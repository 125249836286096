import React from 'react';
import axios from 'axios';  // Importar axios para realizar la solicitud HTTP
import { useNavigate } from 'react-router-dom';
 

const Confirmacion = ({
  onClose,
  onSuccessfulSubmit, 
  fechaCotizacion,
  cliente,
  cliente_id,
  direccion,
  telefono,
  items,
  totalM2,
  totalCotizacion,
  descuento,
  iva,
  tipoInstalacion, 
  retiroLaminas,
  metrosRetirar,
  montoRetiro,
  cobroDistancia,
  montoDistancia,
  tipoDificultad,
  montoDificultad,
  notasAdicionales,
  tipoPropiedad,
  metodoPago,  // Este campo es específico para órdenes de venta
  tipo,  // Nuevo parámetro para determinar si es una cotización o una orden de venta
}) => {
  const navigate = useNavigate();  // Inicializa useNavigate para redirigir
  
  const handleSubmit = async () => {
    let data;
    let url;

    // Convertir todos los valores relevantes a números antes de enviarlos
    const montoRetiroNumero = parseFloat(montoRetiro) || 0;
    const montoDistanciaNumero = parseFloat(montoDistancia) || 0;
    const montoDificultadNumero = parseFloat(montoDificultad) || 0;
    const descuentoNumero = parseFloat(descuento) || 0;
    const totalCotizacionNumero = parseFloat(totalCotizacion) || 0;
    console.error("TOTAL: ",totalCotizacion)
    const generarDetallesSegunCantidad = (items) => {
      return items.flatMap(item => {
        const cantidad = parseInt(item.cantidad, 10) || 1; // Tomamos en cuenta la cantidad
        return Array(cantidad).fill(null).map(() => ({
          item: item.lamina,
          alto: parseFloat(item.alto) || 0,
          ancho: parseFloat(item.ancho) || 0,
          m2: parseFloat(item.m2) || 0, // M2 ya calculado
          total: parseFloat(item.total) || 0, // Total ya calculado
        }));
      });
    };
  

    if (tipo === "cotizacion") {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/`;
        data = {
            solicitante: cliente_id,
            estado: 'pendiente',
            direccion_instalacion: direccion,
            retiro_laminas: retiroLaminas === 'Sí',
            monto_retiro_laminas: montoRetiroNumero,
            cobro_distancia: cobroDistancia || false,
            monto_distancia: montoDistanciaNumero,
            dificultad: tipoDificultad,
            monto_dificultad: montoDificultadNumero,
            descuento: descuentoNumero,
            total_cotizacion: totalCotizacionNumero,
            tipo_instalacion: tipoInstalacion,
            tipo_propiedad: tipoPropiedad,
            notas: notasAdicionales || '',
            // Usamos la función para desglosar ítems según la cantidad
            detalles: generarDetallesSegunCantidad(items)
        };
    } else if (tipo === "orden") {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/ordenes/crear-orden/`;
        data = {
            cotizacion: {
                solicitante: cliente_id,
                direccion_instalacion: direccion,
                tipo_instalacion: tipoInstalacion,
                retiro_laminas: retiroLaminas === 'Sí',
                tipo_propiedad: tipoPropiedad,
                monto_retiro_laminas: montoRetiroNumero,
                cobro_distancia: cobroDistancia || false,
                monto_distancia: montoDistanciaNumero,
                dificultad: tipoDificultad,
                monto_dificultad: montoDificultadNumero,
                descuento: descuentoNumero,
                total_cotizacion: totalCotizacionNumero,
                notas: notasAdicionales || '',
                // Usamos la función para desglosar ítems según la cantidad
                detalles: generarDetallesSegunCantidad(items)
                    },
                metodo_pago: metodoPago  // Método de pago se agrega solo en las órdenes de venta
              };
    }

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        console.log('Envío exitoso:', response.data);
        onSuccessfulSubmit();
    } catch (error) {
        console.error('Error al enviar:', error);
    }
};

  return (
    <div className="bg-half-transparent w-screen h-screen fixed nav-item top-0 left-0 flex items-center justify-center">
      <div className="bg-white dark:bg-[#484B52] p-8 rounded-lg w-full max-w-lg mx-auto" style={{ height: '90vh', overflowY: 'auto' }}>
        <div className="mb-4">
          <h2 className="text-2xl font-bold mb-6">{tipo === 'cotizacion' ? 'Confirmación de Cotización' : 'Confirmación de Orden de Venta'}</h2>
          <p className="text-sm text-gray-500">{tipoInstalacion.toUpperCase()}</p>
        </div>

        <div className="mb-4">
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Fecha:</p>
            <p className="text-sm">{fechaCotizacion}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Solicitante:</p>
            <p className="text-sm">{cliente}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Dirección:</p>
            <p className="text-sm">{direccion}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Telefono/wsp:</p>
            <p className="text-sm">{telefono}</p>
          </div>
        </div>



        <div className="mb-4">
          <p className="font-semibold text-gray-700">Items</p>
          <div className="border rounded-md p-4">
            <table className="w-full text-sm">
              <thead>
                <tr>
                  <th className="text-left text-gray-500">Lámina</th>
                  <th className="text-left text-gray-500">Cant.</th>
                  <th className="text-left text-gray-500">Alto</th>
                  <th className="text-left text-gray-500">Ancho</th>
                  <th className="text-left text-gray-500">M²</th>
                  <th className="text-left text-gray-500">Total</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.lamina}</td>
                    <td>{item.cantidad}</td>
                    <td>{item.alto} m</td>
                    <td>{item.ancho} m</td>
                    <td>{item.m2}</td>
                    <td>{item.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Sección de montos adicionales */}
            <div className="flex justify-between mt-4">
              <p className="text-sm">Total m²:</p>
              <p className="text-sm">{totalM2} m²</p>
            </div>

            {/* Monto por retiro de láminas */}
            <div className="flex justify-between mt-2">
              <p className="text-sm">Monto retiro de láminas:</p>
              <p className="text-sm">${(montoRetiro || 0).toLocaleString()}</p>
            </div>

            {/* Monto por distancia */}
            <div className="flex justify-between mt-2">
              <p className="text-sm">Monto cobro por distancia:</p>
              <p className="text-sm">${(montoDistancia || 0).toLocaleString()}</p>
            </div>

            {/* Monto por dificultad */}
            <div className="flex justify-between mt-2">
              <p className="text-sm">Monto por dificultad:</p>
              <p className="text-sm">${(montoDificultad || 0).toLocaleString()}</p>
            </div>
            
            {descuento > 0 && (
              <div className="flex justify-between mt-2">
                <p className="text-sm">Descuento:</p>
                <p className="text-sm">- {descuento}%</p>
              </div>
            )}

            <div className="flex justify-between mt-2">
              <p className="text-sm">IVA:</p>
              <p className="text-sm">${(iva || 0).toLocaleString()}</p>
            </div>
            <div className="flex justify-between mt-2">
              <p className="text-sm">Total:</p>
              <p className="text-sm">${(totalCotizacion || 0).toLocaleString()}</p>
            </div>
          </div>
        </div>

        {/* Información resumida del tipo de instalación, retiro de láminas, distancia y dificultad */}
        <div className="mb-4">
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Tipo de instalación:</p>
            <p className="text-sm">{tipoInstalacion}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Retiro de láminas:</p>
            <p className="text-sm">
              {retiroLaminas === 'Sí'
                ? `${retiroLaminas} ${metrosRetirar || 0} m²`
                : 'No'}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Cobro por distancia:</p>
            <p className="text-sm">{cobroDistancia ? 'Sí' : 'No'}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Dificultad:</p>
            <p className="text-sm">
              {tipoDificultad !== 'Normal' ? `${tipoDificultad}` : 'Normal'}
            </p>
          </div>
          {tipoPropiedad && (
            <div className="flex justify-between">
              <p className="text-sm text-gray-500">Tipo de propiedad:</p>
              <p className="text-sm">
                {tipoPropiedad}
              </p>
            </div>
          )}

          {tipo === 'orden' && (
            <div className="flex justify-between">
              <p className="text-sm text-gray-500">Método de pago:</p>
              <p className="text-sm">
                {metodoPago}
              </p>
            </div>
          )}
        </div>

        <div className="mb-4">
          <p className="text-sm text-gray-500">Notas adicionales:</p>
          <p className="text-sm">{notasAdicionales || 'No se ingresaron notas adicionales.'}</p>
        </div>

        <div className="flex justify-between mt-6">
          <button
            onClick={onClose}
            className="bg-gray-300 p-2 rounded text-gray-700"
          >
            Cerrar
          </button>
          <button
            onClick={handleSubmit}  // Llamada a la función handleSubmit al hacer clic en "Enviar"
            className="p-2 rounded text-white"
            style={{ backgroundColor: '#FFa800' }}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmacion;
