import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RiCloseCircleLine } from "react-icons/ri";
import { useStateContext } from '../contexts/ContextProvider';
import { FaWaze } from 'react-icons/fa'; 
import moment from 'moment';

const DetalleInstalacion = ({ onClose, instalacion, cotizacion, orden }) => {
  const { currentColor } = useStateContext();
  const [uploadedFiles, setUploadedFiles] = useState([]); 
  const [orderConfirmed, setOrderConfirmed] = useState(false); 
  const [customerContacted, setCustomerContacted] = useState(false); 
  const [confirmationTime, setConfirmationTime] = useState(''); 
  const [contactTime, setContactTime] = useState(''); 
  const [cliente, setCliente] = useState(null);  
  const [mostrarFormulario, setMostrarFormulario] = useState(false); // Variable para mostrar el formulario
  const [detalleCotizacion, setDetalleCotizacion] = useState({
    HoraSalida: '',
    ImagenesLink: '',
    Notas: ''
  });

  useEffect(() => {
    const fetchInstalacionData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          console.error('No se ha encontrado el token de autenticación.');
          return;
        }
  
        // Llamada a la API para obtener detalles de la instalación por su ID
        const instalacionResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/instalaciones/${instalacion.id_instalacion}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        const instalacionData = instalacionResponse.data;
  
        // Verificar si confirmar_recibida y confirmar_contacto tienen fecha
        if (instalacionData.confirmar_recibida) {
          setOrderConfirmed(true); // Marcar el checkbox
          setConfirmationTime(moment(instalacionData.confirmar_recibida).format('YYYY-MM-DDTHH:mm:ssZ')); // Mostrar la hora
        }
        if (instalacionData.confirmar_contacto) {
          setCustomerContacted(true); // Marcar el checkbox
          setContactTime(moment(instalacionData.confirmar_contacto).format('YYYY-MM-DDTHH:mm:ssZ')); // Mostrar la hora
        }
  
        // Mostrar el formulario si confirmar_recibida y confirmar_contacto están presentes
        if (instalacionData.confirmar_recibida && instalacionData.confirmar_contacto) {
          setMostrarFormulario(true);
        } else {
          setMostrarFormulario(false);
        }
  
      } catch (error) {
        console.error('Error al obtener los detalles de la instalación:', error);
      }
    };
  
    fetchInstalacionData();
  
    const fetchCliente = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          console.error('No se ha encontrado el token de autenticación.');
          return;
        }
  
        if (cotizacion.solicitante) {
          const clienteResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/clientes/clientes/${cotizacion.solicitante}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setCliente(clienteResponse.data);
        }
      } catch (error) {
        console.error('Error al obtener los detalles del cliente:', error);
      }
    };
  
    fetchCliente();
  }, [cotizacion.solicitante, instalacion.id_instalacion]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetalleCotizacion((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleCerrarInstalacion = async () => {
    const { HoraSalida, Notas } = detalleCotizacion;
  
    if (!orderConfirmed) {
      alert('Debes confirmar que has recibido y leído la orden de instalación.');
      return;
    }
  
    if (!customerContacted) {
      alert('Debes confirmar que el cliente ha sido contactado para confirmar la fecha de instalación.');
      return;
    }
  
    if (!HoraSalida || uploadedFiles.length === 0) {
      alert('Todos los campos son obligatorios: "Hora de salida" y "Cargar imágenes" deben estar completados.');
      return;
    }
  
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No se ha encontrado el token de autenticación.');
        return;
      }
  
      const data = {
        estado_instalacion: 'realizada',
        hora_salida: new Date().toISOString(),  // Hora actual en formato ISO
        imagenes: uploadedFiles.map((file) => file.name),  // Rutas de las imágenes subidas
        notas_instalacion: Notas || 'La instalación se completó exitosamente y se contactó al cliente.',
      };
  
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/instalaciones/${instalacion.id_instalacion}/`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      alert('Instalación cerrada con éxito');
      onClose();  // Cerrar el modal después de la actualización exitosa
    } catch (error) {
      console.error('Error al actualizar la instalación:', error);
      alert('Hubo un error al cerrar la instalación.');
    }
  };


  const handleGuardar = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No se ha encontrado el token de autenticación.');
        return;
      }

      // Datos a enviar en el PATCH request
      const data = {
        confirmar_recibida: moment().format(),  // Hora actual
        confirmar_contacto: moment().format()   // Hora actual
      };

      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/instalaciones/${instalacion.id_instalacion}/`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      alert('Estado de la instalación actualizado con éxito.');
      setMostrarFormulario(true);  // Muestra el formulario después de actualizar
    } catch (error) {
      console.error('Error al actualizar la instalación:', error);
      alert('Hubo un error al actualizar la instalación.');
    }
  };

  const handleOrderConfirmation = (e) => {
    const isChecked = e.target.checked;
    setOrderConfirmed(isChecked);

    if (isChecked) {
      const currentTime = moment().format('YYYY-MM-DDTHH:mm:ssZ');
      setConfirmationTime(currentTime);  // Guarda el tiempo de confirmación
    } else {
      setConfirmationTime('');
    }
  };

  const handleCustomerContacted = (e) => {
    const isChecked = e.target.checked;
    setCustomerContacted(isChecked);

    if (isChecked) {
      const currentTime = moment().format('YYYY-MM-DDTHH:mm:ssZ');
      setContactTime(currentTime);  // Guarda el tiempo de contacto
    } else {
      setContactTime('');
    }
  };

  const totalM2 = cotizacion.detalles.reduce((acc, item) => acc + parseFloat(item.m2 || 0), 0);

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Instalación</p>
            <button
              type="button"
              onClick={onClose}
              style={{ color: currentColor }}
              className="relative text-3xl rounded-full p-4 hover:bg-light-gray"
            >
              <RiCloseCircleLine />
            </button>
          </div>

          <div className="cliente-info">
            <div className="left-info">
              <p><strong>Cliente:</strong></p>
              {cliente ? (
                <>
                  <p>{cliente.nombre} {cliente.apellido}</p>
                  <p>{cotizacion.direccion_instalacion}</p>
                  <p>{cliente.email}</p>
                  <p>{cliente.whatsapp}</p>
                </>
              ) : (
                <p>Cargando información del cliente...</p>
              )}
              <a
                href={`https://waze.com/ul?ll=${cotizacion.direccion_instalacion}&navigate=yes`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center mt-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
              >
                <FaWaze className="mr-2" /> Abrir en Waze
              </a>
            </div>
            <div className="right-info">
              <p><strong>Nº Cotización:</strong> {cotizacion.id_cotizacion}</p>
              <p><strong>Fecha Instalación: {moment(orden.fecha_instalacion).format('YYYY-MM-DD')}</strong></p>
              <p><strong>Hora Instalación: {moment(orden.fecha_instalacion).format('HH:mm:ss')}</strong> </p>
              <p><strong>Estado:</strong> {instalacion.estado_instalacion}</p>
              <p><strong>Tipo Servicio:</strong> {cotizacion.tipo_instalacion}</p>
              <p><strong>Retiro Lámina:</strong> {cotizacion.retiro_laminas ? 'Sí' : 'No'}</p>
              <p><strong>Dificultad:</strong> {cotizacion.dificultad}</p>
              <p><strong>Tipo Propiedad:</strong> {cotizacion.tipo_propiedad}</p>
            </div>
          </div>

{/* Confirmación de orden de instalación */}
<div className="mt-4 mb-6">
  <label className="inline-flex items-center">
    <input
      type="checkbox"
      className="form-checkbox"
      checked={orderConfirmed}
      onChange={handleOrderConfirmation}
      disabled={orderConfirmed}
    />
    <span className="ml-2 text-gray-700">Confirmo que he recibido y leído la orden de instalación</span>
  </label>
  {confirmationTime && (
    <p className="mt-2 text-green-600">{`Confirmada: ${confirmationTime}`}</p>
  )}
</div>

{/* Confirmación de contacto con el cliente */}
<div className="mt-4 mb-6">
  <label className="inline-flex items-center">
    <input
      type="checkbox"
      className="form-checkbox"
      checked={customerContacted}
      onChange={handleCustomerContacted}
      disabled={customerContacted}
    />
    <span className="ml-2 text-gray-700">Confirmo que el cliente ha sido contactado para confirmar la fecha de instalación</span>
  </label>
  {contactTime && (
    <p className="mt-2 text-green-600">{`Contacto: ${contactTime}`}</p>
  )}
</div>

          <table className="items-table">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Item</th>
                <th>Alto</th>
                <th>Ancho</th>
                <th>M²</th>
              </tr>
            </thead>
            <tbody>
              {cotizacion.detalles && cotizacion.detalles.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.item}</td>
                  <td>{item.alto || 'N/A'}</td>
                  <td>{item.ancho || 'N/A'}</td>
                  <td>{item.m2 ? `${item.m2} m²` : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Mostrar el formulario o el botón basado en mostrarFormulario */}
          {mostrarFormulario ? (
            <>
              {/* Formulario para el instalador */}
              <div className="mt-6">
                <h3 className="font-semibold text-lg mb-4">Registros de instalación</h3>
                <div className="mb-4">
                  <label className="block text-gray-700">Hora de salida</label>
                  <input
                    type="time"
                    name="HoraSalida"
                    className="w-full p-2 border rounded"
                    value={detalleCotizacion.HoraSalida}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Cargar imágenes</label>
                  <input
                    type="file"
                    className="w-full p-2 border rounded"
                    multiple
                    onChange={handleFileUpload}
                  />
                  <ul className="mt-4">
                    {uploadedFiles.map((file, index) => (
                      <li key={index} className="text-gray-700">
                        {file.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Notas</label>
                  <textarea
                    name="Notas"
                    className="w-full p-2 border rounded"
                    rows="4"
                    placeholder="Notas adicionales sobre la instalación..."
                    value={detalleCotizacion.Notas}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200"
                  onClick={handleCerrarInstalacion}
                >
                  Cerrar instalación
                </button>
              </div>
            </>
          ) : (
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
              onClick={handleGuardar}
            >
              Guardar
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default DetalleInstalacion;
