import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject as GridInject, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { Header } from '../components';
import Scheduler from './Calendar'; // Importa el componente Scheduler desde Calendar.jsx

const AgendaPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Gestión de Instalaciones" />

      <div className="flex justify-between items-center mb-4">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="agenda tabs"
          sx={{
            '.MuiTab-root': {
              '&:hover': { color: '#FFa800' },
              '&.Mui-selected': { color: '#FFa800' },
            },
            '.MuiTabs-indicator': { backgroundColor: '#FFa800' },
          }}
        >
          <Tab label="Calendario" />
          <Tab label="Histórico" />
          <Tab label="Instalaciones" />
        </Tabs>
      </div>

      {selectedTab === 0 && (
          <Scheduler /> 
      )}

      {selectedTab === 1 && (
        <div className="p-4 bg-white rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Histórico de Instalaciones</h2>
          <GridComponent
            dataSource={[]}
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
          >
            <ColumnsDirective>
              <ColumnDirective field="Fecha" headerText="Fecha" width="100" textAlign="Center" />
              <ColumnDirective field="Cliente" headerText="Cliente" width="100" textAlign="Center" />
              <ColumnDirective field="Estado" headerText="Estado" width="100" textAlign="Center" />
              <ColumnDirective field="Comentario" headerText="Comentarios" width="150" textAlign="Center" />
            </ColumnsDirective>
            <GridInject services={[Page, Sort, Filter]} />
          </GridComponent>
        </div>
      )}

      {selectedTab === 2 && (
        <div className="p-4 bg-white rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Próximas Instalaciones</h2>
          <GridComponent
            dataSource={[]}
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
          >
            <ColumnsDirective>
              <ColumnDirective field="Fecha" headerText="Fecha" width="100" textAlign="Center" />
              <ColumnDirective field="Ubicacion" headerText="Ubicación" width="150" textAlign="Center" />
              <ColumnDirective field="Lamina" headerText="Tipo de Lámina" width="150" textAlign="Center" />
              <ColumnDirective field="Instalador" headerText="Instalador" width="100" textAlign="Center" />
              <ColumnDirective field="Duracion" headerText="Duración" width="100" textAlign="Center" />
            </ColumnsDirective>
            <GridInject services={[Page, Sort, Filter]} />
          </GridComponent>
        </div>
      )}
    </div>
  );
};

export default AgendaPage;
