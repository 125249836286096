import React, { useState, useEffect } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, WorkWeek, Month, Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { L10n, loadCldr } from '@syncfusion/ej2-base';
import axios from 'axios';

// Importa los archivos CLDR para español
import numberingSystems from '@syncfusion/ej2-cldr-data/supplemental/numberingSystems.json';
import caGregorian from '@syncfusion/ej2-cldr-data/main/es/ca-gregorian.json';
import numbers from '@syncfusion/ej2-cldr-data/main/es/numbers.json';
import timeZoneNames from '@syncfusion/ej2-cldr-data/main/es/timeZoneNames.json';

// Cargar los datos CLDR
loadCldr(numberingSystems, caGregorian, numbers, timeZoneNames);

// Cargar los textos de localización para español
L10n.load({
  'es': {
    'schedule': {
      'day': 'Día',
      'workWeek': 'Semana laboral',
      'month': 'Mes',
      'agenda': 'Agenda',
      'today': 'Hoy',
      'noEvents': 'No hay eventos',
      'allDay': 'Todo el día',
      'start': 'Inicio',
      'end': 'Fin',
      'more': 'más',
      'close': 'Cerrar',
    },
    'datepicker': {
      'placeholder': 'Fecha actual',
      'today': 'Hoy',
    }
  }
});

const Scheduler = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [scheduleData, setScheduleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Función para avanzar al próximo lunes si es sábado o domingo
  const adjustDateIfWeekend = (date) => {
    const day = date.getDay(); // 0 es domingo, 6 es sábado
    if (day === 0) { // Si es domingo
      return new Date(date.setDate(date.getDate() + 1)); // Ir al lunes
    }
    if (day === 6) { // Si es sábado
      return new Date(date.setDate(date.getDate() + 2)); // Ir al lunes
    }
    return date; // Si no es fin de semana, retornar la fecha actual
  };

  useEffect(() => {
    // Ajustar la fecha inicial si es fin de semana
    setSelectedDate(adjustDateIfWeekend(new Date()));
  }, []);

  // Función para obtener eventos desde la API
  const fetchEvents = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('access_token'); // Asegúrate de que el token esté disponible
      if (!token) {
        setError('No se ha encontrado el token de autenticación. Por favor, inicia sesión.');
        setIsLoading(false);
        return;
      }
      

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/events/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response && response.data) {
        // Mapea los campos de la API a los campos esperados por Syncfusion
        
        const mappedEvents = response.data.map(event => ({
          Id: event.id,
          Subject: event.subject,
          Location: event.location,
          StartTime: new Date(event.start_time),
          EndTime: new Date(event.end_time),
          CategoryColor: event.category_color,
          Description: event.description,
          Vehicle: event.vehicle,
          Order: event.order
        }));
        console.error("mappedEvents",mappedEvents);
        setScheduleData(mappedEvents); // Actualiza el estado con los eventos mapeados
      } else {
        setError('No se recibieron datos del servidor.');
      }
    } catch (error) {
      setError('Error al cargar los eventos.');
      console.error('Error fetching events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents(); // Cargar eventos cuando el componente se monta
  }, []);

  const change = (args) => {
    const adjustedDate = adjustDateIfWeekend(args.value);
    setSelectedDate(adjustedDate);
  };

  const onDragStart = (arg) => {
    arg.navigation.enable = true;
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {isLoading ? (
        <p>Cargando eventos...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <ScheduleComponent
          height="650px"
          selectedDate={selectedDate}
          eventSettings={{ dataSource: scheduleData }} // Eventos cargados desde la API
          dragStart={onDragStart}
          startHour="09:00"
          endHour="18:00"
          firstDayOfWeek={1}
          locale='es'
          currentView="WorkWeek" 
          timezone='America/Santiago'
          workDays={[1, 2, 3, 4, 5, 6]}  // De lunes a sábado
        >
          <ViewsDirective>
            <ViewDirective option='Day' />
            <ViewDirective option='WorkWeek' />
            <ViewDirective option='Month' showWeekend={false} />
            <ViewDirective option='Agenda' />
          </ViewsDirective>
          <Inject services={[Day, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />
        </ScheduleComponent>
      )}

      <div className="mt-5">
        <table style={{ width: '100%', background: 'white' }}>
          <tbody>
            <tr style={{ height: '50px' }}>
              <td style={{ width: '100%' }}>
                <DatePickerComponent
                  value={selectedDate}
                  showClearButton={false}
                  placeholder="Fecha actual" 
                  floatLabelType="Always"
                  change={change}
                  locale="es" 
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Scheduler;
