import React, { useState,useEffect  } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios';

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const DetalleStock = ({ onClose, stock = {}, categoria, tabIndex  }) => {
  const [stockId, setStockId] = useState(null); // Estado para guardar el ID de Stock
  const { currentColor } = useStateContext();
  const [recargasHistorico, setRecargasHistorico] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [activeButton, setActiveButton] = useState(null); // Nuevo estado para manejar botones activos
  const [editStock, setEditStock] = useState(stock.unidades || ''); 
  const [editComment, setEditComment] = useState('');
  const [stockAsignar, setStockAsignar] = useState(''); 
  const [asignarVisible, setAsignarVisible] = useState(false);
  const [proveedor, setProveedor] = useState(''); // Nuevo estado para manejar el proveedor

  const handleStockEditChange = (e) => setEditStock(e.target.value);
  const handleEditCommentChange = (e) => setEditComment(e.target.value);
  const handleStockAsignarChange = (e) => setStockAsignar(e.target.value);
  const handleProveedorChange = (e) => setProveedor(e.target.value);
  
   // Actualizar el valor de editStock cuando el stock cambie
   useEffect(() => {
    if (stock.cantidad_bodega !== undefined) {
      setEditStock(stock.cantidad_bodega);  // Si existe el valor, lo asigna a editStock
    }
  }, [stock.cantidad_bodega]); 
  
  useEffect(() => {
    // Función para obtener el ID del Stock específico de la bodega
    const fetchStockId = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/productos/stocks/`, {
          params: {
            lamina_id: stock.id,  // ID de la lámina
            ubicacion: 'bodega',  // Ubicación específica
          },
        });

        if (response.data.length > 0) {
          // Asumiendo que la API devuelve un array de resultados
          setStockId(response.data[0].id); // Guardar el ID del Stock en el estado
        }
      } catch (error) {
        console.error('Error fetching stock ID:', error);
      }
    };
    if (stock.id) {
      fetchStockId();
    }
  }, [stock.id]);

  const handleSave = async () => {
    console.error("save")
    if (activeButton === 'edit') {
      await handleSaveEdit();
    } else if (activeButton === 'add') {
      await handleAddStock();
    } else if (activeButton === 'assign') {
      await handleAssignStock();
    }
    setActiveButton(null);
  };

  const handleCancel = () => {
    setActiveButton(null);
    setFormVisible(false);
    setEditMode(false);
    setAsignarVisible(false);
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
    if (button === 'edit') {
      setEditMode(true);
    } else if (button === 'add') {
      setFormVisible(true);
    } else if (button === 'assign') {
      setAsignarVisible(true);
    }
  };

  const handleSaveEdit = async () => {
    if (editComment.trim() === '') {
      alert('Por favor, agrega un comentario para justificar la modificación del stock.');
      return;
    }
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        alert('No se ha encontrado el token de autenticación. Por favor, inicia sesión.');
        return;
      }

      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/laminas/${stock.codigo}/`, {
        unidades: editStock,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      registrarStockEnHistorico(stock.codigo, editStock, 'Modificación manual', 'Ajuste manual');

      setRecargasHistorico([
        ...recargasHistorico,
        {
          Fecha: new Date().toISOString().split('T')[0],
          Cantidad: editStock,
          Proveedor: 'Manual',
          Comentarios: editComment,
        },
      ]);
      setEditMode(false);
      setEditComment('');
    } catch (error) {
      console.error('Error guardando el stock editado:', error);
    }
  };

  const handleAssignStock = async () => {
    if (!stockAsignar || stockAsignar <= 0 || stockAsignar > stock.unidades) {
      alert('La cantidad a asignar no es válida.');
      return;
    }
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        alert('No se ha encontrado el token de autenticación. Por favor, inicia sesión.');
        return;
      }

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/laminas/asignar-stock/`, {
        codigo_bodega: stock.codigo,
        cantidad_asignada: stockAsignar,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setRecargasHistorico([
        ...recargasHistorico,
        {
          Fecha: new Date().toISOString().split('T')[0],
          Cantidad: stockAsignar,
          Proveedor: 'Asignación a auto',
          Comentarios: 'Asignación de stock a láminas auto',
        },
      ]);
      alert('Stock asignado con éxito');
      setAsignarVisible(false);
    } catch (error) {
      console.error('Error asignando stock:', error);
    }
  };

  const handleAddStock = async () => {
    if (editStock.trim() === '' || proveedor.trim() === '') {
      alert('Por favor, ingresa la cantidad de láminas y el proveedor.');
      return;
    }
    console.error("agregamos")
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        alert('No se ha encontrado el token de autenticación. Por favor, inicia sesión.');
        return;
      }
  
      const stockId = stock.id_bodega; // Asumiendo que `stock.id_bodega` es el ID del stock en bodega.
      console.error("id codigo",stock.id)
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/productos/stocks/`, {
        lamina_id: stock.id, 
        ubicacion: "bodega",
        cantidad: parseInt(editStock, 10),                      
        vehiculo: null, 
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      console.log("Respuesta de la API al guardar stock:", response.data);
  
      // Registrar en el historial de stock
      await registrarStockEnHistorico(stock.codigo, editStock, proveedor, 'Recarga de stock', 'recarga_stock');
  
      setRecargasHistorico([
        ...recargasHistorico,
        {
          Fecha: new Date().toISOString().split('T')[0],
          Cantidad: editStock,
          Proveedor: proveedor,
          Comentarios: 'Recarga de stock',
        },
      ]);
      setFormVisible(false);
      setProveedor('');
      setEditStock('');
    } catch (error) {
      console.error('Error agregando el stock:', error);
    }
  };

  const registrarStockEnHistorico = async (codigo, cantidad, proveedor, comentario, tipo_modificacion) => {
    const token = localStorage.getItem('access_token'); 

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/historico_stock/registrar/`, {
        content_type: "productos.lamina",  
        object_id: codigo,                     
        cantidad: parseInt(cantidad, 10),                      
        proveedor: proveedor, 
        comentario: comentario,
        tipo_modificacion: tipo_modificacion
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
    } catch (error) {
      console.error('Error registrando en el historial de stock:', error);
    }
  };

  const renderForm = () => {
    if (activeButton === 'add') {
      return (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Agregar Stock</h3>
          <div className="flex flex-col">
            <label className="mt-2">Cantidad de Láminas:</label>
            <input
              type="number"
              value={editStock}
              onChange={handleStockEditChange}
              className="border p-2 rounded-lg"
            />
            <label className="mt-2">Proveedor:</label>
            <input
              type="text"
              value={proveedor}
              onChange={handleProveedorChange}
              className="border p-2 rounded-lg"
            />
          </div>
        </div>
      );
    }
    if (activeButton === 'edit') {
      return (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Editar Stock</h3>
          <div className="flex flex-col">
            <label className="mt-2">Cantidad:</label>
            <input
              type="number"
              value={editStock}
              onChange={handleStockEditChange}
              className="border p-2 rounded-lg"
            />
          </div>
        </div>
      );
      
    }
    if (activeButton === 'assign') {
      return (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Asignar Lamina a Vehiculo</h3>
          <div className="flex flex-col">
            <label className="mt-2">Cantidad:</label>
            <input
              type="number"
              value={editStock}
              onChange={handleStockEditChange}
              className="border p-2 rounded-lg"
            />
          </div>
        </div>
      );
      
    }
    // Implementar formularios similares para 'edit' y 'assign' si se requieren
    return null;
  };

  const renderStockDetails = () => {
    if (!stock || Object.keys(stock).length === 0) {
      return <p>No hay información del stock disponible.</p>;
    }
    switch (categoria) {
      case 'lamina':
        return (
          <>
            <div className="left-info">
              <p><strong>Código:</strong> {stock.codigo || 'N/A'}</p>
              <p><strong>Nombre:</strong> {stock.nombre || 'N/A'}</p>
              <p><strong>Descripción:</strong> {stock.descripcion || ''}</p>
              <p><strong>Precio m²:</strong> {stock.preciom2 || 'N/A'}</p>
            </div>
            <div className="right-info">
            {tabIndex === 0 && (
              <>
                <p><strong>Rendimiento:</strong> {stock.rendimiento || 'N/A'} m²</p>
                <p><strong>Stock bodega:</strong> {stock.cantidad_bodega || '0'} rollos</p>
                <p><strong>Rendimiento Total: </strong> 
                  {stock.rendimiento && stock.cantidad_bodega ? 
                    (parseFloat(stock.rendimiento) * parseFloat(stock.cantidad_bodega)).toFixed(2) 
                    : '0'} m²
                </p>
              </>
            )}
            {tabIndex === 1 && (
              <>
                <p><strong>Rendimiento:</strong> {stock.rendimiento || 'N/A'} m²</p>
                <p><strong>Stock auto:</strong> {stock.cantidad_vehiculos || '0'} rollos</p>
                <p><strong>Rendimiento Total: </strong> 
                  {stock.rendimiento && stock.cantidad_vehiculos ? 
                    (parseFloat(stock.rendimiento) * parseFloat(stock.cantidad_vehiculos)).toFixed(2) 
                    : '0'} m²
                </p>
              </>
            )}
          </div>
          </>
        );
      case 'herramienta':
        return (
          <>
            <div className="left-info">
              <p><strong>Código:</strong> {stock.codigo || 'N/A'}</p>
              <p><strong>Nombre:</strong> {stock.nombre || 'N/A'}</p>
              <p><strong>Marca:</strong> {stock.marca || 'N/A'}</p>
              
            </div>
            <div className="right-info">
              <p><strong>Unidades en stock:</strong> {stock.unidades || '0'}</p>
              <p><strong>Descripción:</strong> {stock.descripcion || 'N/A'}</p>
            </div>
          </>
        );
  
      case 'vehiculo':
        return (
          <>
            <div className="left-info">
              <p><strong>Patente:</strong> {stock.codigo || 'N/A'}</p>
              <p><strong>Modelo:</strong> {stock.modelo || 'N/A'}</p>
              <p><strong>Nombre:</strong> {stock.nombre || 'N/A'}</p>
            </div>
            <div className="right-info">
            <p><strong>Marca:</strong> {stock.marca || 'N/A'}</p>
            <p><strong>Descripcion:</strong> {stock.descripcion }</p>

            </div>
          </>
        );
  
      default:
        return <p>No hay detalles disponibles para esta categoría.</p>;
      }
    };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
      <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
        <div className="flex justify-between items-center mb-6">
          <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle de Stock</p>
          <NavButton
            title="Cerrar"
            customFunc={onClose}
            color={currentColor}
            icon={<RiCloseCircleLine />}
            size="3xl"
            padding="p-4"
          />
        </div>

        <div className="cotizacion-header">
          <h1 className="cotizacion-title">DETALLE STOCK</h1>
        </div>

        <div className="cliente-info">
          {renderStockDetails()}
        </div>

        {/* Aquí se renderiza el formulario según la acción seleccionada */}
        {renderForm()}
        {categoria === 'lamina' && (
        <div className="mt-6">
          <h2 className="font-semibold text-lg" style={{ color: '#FFa800' }}>Histórico de Recargas</h2>
          <GridComponent
            dataSource={recargasHistorico}
            allowPaging={true}
            pageSettings={{ pageCount: 5 }}
            allowSorting={true}
            allowFiltering={true}
          >
            <ColumnsDirective>
              <ColumnDirective field="Fecha" headerText="Fecha" width="100" textAlign="Center" />
              <ColumnDirective field="Cantidad" headerText="Cantidad" width="100" textAlign="Center" />
              <ColumnDirective field="Proveedor" headerText="Proveedor" width="150" textAlign="Center" />
              <ColumnDirective field="Comentarios" headerText="Comentarios" width="200" textAlign="Center" />
            </ColumnsDirective>
            <Inject services={[Page, Sort, Filter]} />
          </GridComponent>
        </div>
        )}
        <div className="flex justify-between mt-4">
          {activeButton === null && (
            <>
              {categoria === 'lamina' && (
                <button
                  type="button"
                  className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200 mr-4"
                  onClick={() => handleButtonClick('add')}
                >
                  Agregar Stock
                </button>
              )}
              {stock.cantidad_bodega > 0 && (
              <>
                <button
                  type="button"
                  className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-200"
                  onClick={() => handleButtonClick('edit')}
                >
                  Editar Stock
                </button>
                {categoria === 'lamina' && (
                  <button
                    type="button"
                    className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200 ml-4"
                    onClick={() => handleButtonClick('assign')}
                  >
                    Asignar Láminas
                  </button>
                )}
              </>
              )}
          </>
          )}

          {activeButton !== null && (
            <>
              <button
                type="button"
                className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200"
                onClick={handleCancel}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200 mr-4"
                onClick={handleSave}
              >
                Guardar
              </button>

            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetalleStock;
