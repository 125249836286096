import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { FaDownload, FaPrint } from 'react-icons/fa';
import { Orden, Header, DetalleOrdenVenta } from '../components';
import { Tabs, Tab } from '@mui/material';
import axios from 'axios';

const Ordenes = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [ordenes, setOrdenes] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [ordenVisible, setOrdenVisible] = useState(false);
  const [detalleOrdenVisible, setDetalleOrdenVisible] = useState(false);
  const [selectedOrden, setSelectedOrden] = useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); 
  };

  const fetchOrdenesData = async () => {
    try {
      // Obtener el token de autenticación desde localStorage o sessionStorage
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No se ha encontrado el token de autenticación.');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,  // Incluye el token en los encabezados
        },
      };

      // Obtener todas las órdenes de venta
      const ordenesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/ordenes/`, config);
      const ordenesData = ordenesResponse.data;

      // Obtener todas las cotizaciones
      const cotizacionesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/`, config);
      const cotizacionesData = cotizacionesResponse.data;

      // Cruzar datos de órdenes y cotizaciones
      const mergedData = ordenesData.map((orden) => {
        const cotizacion = cotizacionesData.find((cot) => cot.id_cotizacion === orden.cotizacion);
        let instalador = orden.instalador;
        let fechaInstalacion = orden.fecha_instalacion;
        if (orden.estado === 'pendiente_asignar') {
          instalador = 'Pendiente asignar';
          fechaInstalacion = 'Pendiente calendarizar';
        } else if (orden.estado === 'por_enviar') {
          instalador = 'N/A';
          fechaInstalacion = 'Pendiente calendarizar';
        } else {
          // Usar valores predeterminados si existen
          instalador = instalador || 'Pendiente asignar';
          fechaInstalacion = fechaInstalacion
            ? new Date(fechaInstalacion).toLocaleDateString()
            : 'Pendiente calendarizar';
        }
        return {
          ...orden,
          estado_orden: orden.estado, // Renombrar el estado de la orden
          estado_cotizacion: cotizacion.estado, // Renombrar el estado de la cotización
          instalador,  // Usar el valor ajustado de instalador
          fecha_instalacion: fechaInstalacion, // Usar el valor ajustado de fecha_instalacion
          ...cotizacion,
        };
      });

      setOrdenes(mergedData);
      setFilteredData(mergedData); // Set filtered data initially to all merged data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const refreshOrdenes = () => {
    fetchOrdenesData(); // Llama a la función que vuelve a cargar las órdenes
  };

  useEffect(() => {
    fetchOrdenesData();
  }, []);

  useEffect(() => {
    filterData(selectedTab);
  }, [selectedTab, ordenes]);

  const filterData = (tabIndex) => {
    let newData = ordenes;
    switch (tabIndex) {
      case 0:
        newData = ordenes.filter(item => item.estado_orden === 'pendiente_asignar');
        break;
      case 1:
        newData = ordenes.filter(item => item.estado_orden === 'por_enviar');
        break;
      case 2:
        newData = ordenes.filter(item => item.estado_orden === 'asignado');
        break;
      case 3:
        newData = ordenes.filter(item => item.estado_orden === 'enviado');
        break;
      case 4:
        newData = ordenes; // Muestra todas las órdenes
        break;
      default:
        newData = ordenes;
    }

    setFilteredData(newData);
  };

  const handleNewOrdenClick = () => {
    setOrdenVisible(true); // Mostrar el formulario de nueva orden de venta
  };

  const handleRowDoubleClick = (args) => {
    const ordenID = args.rowData.id_orden; // Aquí obtienes el ID de la cotización seleccionada
    setSelectedOrden(ordenID); // Guarda la orden seleccionada
    setDetalleOrdenVisible(true); // Mostrar el formulario de cotización al hacer doble clic en una fila
  };



  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Órdenes de Venta" />
      
      <div className="flex justify-between items-center mb-4">
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example"
          sx={{
            '.MuiTab-root': {
              '&:hover': {
                color: '#FFa800', // Cambia el color del texto en hover
              },
              '&.Mui-selected': {
                color: '#FFa800', // Cambia el color del texto cuando la pestaña está seleccionada
              },
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#FFa800', // Cambia el color del subrayado en la pestaña activa
            },
          }}
        >
          <Tab label="Por Asignar" />
          <Tab label="Por Enviar" />
          <Tab label="Asignada" />
          <Tab label="Enviada" />
          <Tab label="Todas las órdenes" />
        </Tabs>
        <button
          type="button"
          onClick={handleNewOrdenClick}
          style={{
            backgroundColor: '#1fb519',  // Verde personalizable
            color: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '8px 16px',
            borderRadius: '8px',
            fontWeight: 'lighter',
            textTransform: 'none',
            border: 'none', // Elimina cualquier borde predeterminado
            cursor: 'pointer', // Cambia el cursor al pasar sobre el botón
          }}
        >
          + Nueva Orden de Venta
        </button>
      </div>
      
      {/* Renderizar formulario de nueva orden */}
      {ordenVisible && (
        <Orden
          onClose={() => setOrdenVisible(false)} // Pasar la función onClose a Orden
          cotizacionId = {filteredData.id_orden}
          refreshOrdenes={refreshOrdenes}  // Refrescar la lista de órdenes
        />
      )}

      {detalleOrdenVisible && selectedOrden && (
        <DetalleOrdenVenta
          onClose={() => setDetalleOrdenVisible(false)} // Pasar la función onClose a DetalleOrdenVenta
          ordenId={selectedOrden} // Pasar la orden seleccionada
          refreshOrdenes={refreshOrdenes}
        />
      )}

      {/* Grilla de órdenes de venta existentes */}
      <GridComponent
        dataSource={filteredData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{ persistSelection: true, type: 'Single' }}  // Selección de filas individuales
        toolbar={[]}
        editSettings={{ allowEditing: false, allowDeleting: false }}  // Desactivar la edición
        allowSorting
        sortSettings={{ columns: [{ field: 'id_orden', direction: 'Descending' }] }}  // Orden descendente por ID
        className="mt-4"
        recordDoubleClick={handleRowDoubleClick}
      >
        <ColumnsDirective>
          <ColumnDirective field="id_orden" headerText="ID" width="120" textAlign="Center" />
          <ColumnDirective field="solicitante_nombre_completo" headerText="Solicitante" width="200" textAlign="Center" />
          <ColumnDirective field="fecha_instalacion" headerText="Fecha Instalación" width="150" format="yMd" textAlign="Center" />
          <ColumnDirective field="estado_orden" headerText="Estado" width="150" textAlign="Center" />
          <ColumnDirective field="total_cotizacion" headerText="Total Cotización" width="150" format="C2" textAlign="Center" />
          <ColumnDirective field="direccion_instalacion" headerText="Dirección Instalación" width="200" textAlign="Center" />
          <ColumnDirective field="instalador" headerText="Instalador" width="150" textAlign="Center" />
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Ordenes;
