import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { Header, Cotizacion, DetalleCotizacion } from '../components';
import { Tabs, Tab } from '@mui/material';
import axios from 'axios';
import moment from 'moment'; // Para manejar fechas

const TabWithIcon = ({ color, label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span
      style={{
        backgroundColor: color,
        borderRadius: '50%',
        width: '8px',
        height: '8px',
        marginRight: '8px',
      }}
    />
    {label}
  </div>
);

const Cotizaciones = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [cotizacionVisible, setCotizacionVisible] = useState(false);
  const [selectedCotizacionId, setSelectedCotizacionId] = useState(null); // Estado para almacenar el ID
  const [detallecotizacionVisible, setDetalleCotizacionVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCotizacion, setSelectedCotizacion] = useState(null); // Nueva variable para la cotización seleccionada

  const fetchCotizacionData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const token = localStorage.getItem('access_token');
      if (!token) {
        setError('No se ha encontrado el token de autenticación. Por favor, inicia sesión.');
        return;
      }

      let response;
      switch (selectedTab) {
        case 0: // Pendiente
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/?estado=pendiente`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          break;
        case 1: // Aprobado
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/?estado=aprobado`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          break;
        case 2: // Cancelado
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/?estado=rechazado`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          break;
        case 3: // Todas las cotizaciones
        default:
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          break;
      }

      if (response && response.data) {
        setFilteredData(response.data);
      } else {
        setError('No se recibieron datos del servidor.');
      }
    } catch (error) {
      setError('Error al cargar las cotizaciones.');
      console.error('Error fetching cotizacion data:', error);
    } finally {
      setIsLoading(false);
    }
  };
const refreshCotizaciones = () => {
    fetchCotizacionData(); // Llama a la función que vuelve a cargar las cotizaciones
  };

  useEffect(() => {
    fetchCotizacionData();
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleNewCotizacionClick = () => {
    setCotizacionVisible(true);
  };


  const handleRowDoubleClick = (args) => {
    const cotizacionId = args.rowData.id_cotizacion; // Aquí obtienes el ID de la cotización seleccionada
    setSelectedCotizacionId(cotizacionId); // Almacena el ID en el estado
    setDetalleCotizacionVisible(true); // Muestra el componente DetalleCotizacion
  };
  
  const isOverdue = (date, status) => {
    const twoWeeksAgo = moment().subtract(14, 'days'); // Fecha límite de hace 14 días
    const recordDate = moment(date, 'DD-MM-YYYY'); // Ajustar el formato a 'DD-MM-YYYY'
    
    // Solo se marca como atrasada si es pendiente y la fecha es anterior a hace 14 días
    return status === 'pendiente' && recordDate.isBefore(twoWeeksAgo, 'day');
  };

  const handleRowDataBound = (args) => {
    if (isOverdue(args.data.fecha_cotizacion, args.data.estado)) {
      args.row.style.backgroundColor = '#FFE5E5'; // Resalta la fila en rojo claro
      args.row.style.color = '#FF0000'; // Cambia el color del texto a rojo
    }
  };
  const formattedData = filteredData.map((item) => ({
    ...item,
    // Aquí dejamos la fecha como un objeto Date para que funcione el ordenamiento
    fecha_cotizacion: new Date(item.fecha_cotizacion),
  }));

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Cotizaciones" />
      
      <div className="flex justify-between items-center">
        <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
          <Tab label={<TabWithIcon color="#FEC90F" label="Pendiente" />} />
          <Tab label={<TabWithIcon color="#8BE78B" label="Aprobado" />} />
          <Tab label={<TabWithIcon color="red" label="Cancelado" />} />
          <Tab label="Todas las cotizaciones" />
        </Tabs>

        <button
          type="button"
          onClick={() => setCotizacionVisible(true)}
          style={{
            backgroundColor: '#1fb519',
            color: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '8px 16px',
            borderRadius: '8px',
            fontWeight: 'lighter',
            textTransform: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          + Nueva Cotización
        </button>
      </div>

      {cotizacionVisible && (
        <Cotizacion
        onClose={() => setCotizacionVisible(false)}
        refreshCotizaciones={refreshCotizaciones}  // Pasa la función para refrescar las cotizaciones
      />
      )}
      {detallecotizacionVisible && (
        <DetalleCotizacion
          cotizacionId={selectedCotizacionId} // Pasamos el ID como prop
          onClose={() => setDetalleCotizacionVisible(false)} // Para cerrar el modal
        />
      )}

      {isLoading ? (
        <p>Cargando datos...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
<GridComponent
  dataSource={formattedData}
  enableHover={false}
  allowPaging
  pageSettings={{ pageCount: 5 }}
  selectionSettings={{ persistSelection: true, type: 'Single' }}  // Selección de filas individuales
  toolbar={[]}
  editSettings={{ allowEditing: false, allowDeleting: false }}  // Desactivar la edición
  allowSorting
  allowMultiSorting={false} // Solo una columna a la vez
  sortSettings={{ columns: [{ field: 'fecha_cotizacion', direction: 'Descending' }] }} // Orden inicial
  className="mt-4"
  recordDoubleClick={handleRowDoubleClick}  // Detectar doble clic en una fila
  rowDataBound={handleRowDataBound}
>
  <ColumnsDirective>
    {/* ID Cotización */}
    <ColumnDirective field="id_cotizacion" headerText="ID" width="100" />

    {/* Solicitante */}
    <ColumnDirective field="solicitante_nombre_completo" headerText="Solicitante" width="150" />

    {/* Fecha Cotización */}
    <ColumnDirective 
      field="fecha_cotizacion" 
      headerText="Fecha" 
      width="150" 
      format="dd/MM/yyyy" // Formato que deseas mostrar
      allowSorting={true} 
    />

    {/* Estado de Cotización con renderizado personalizado */}
    <ColumnDirective
      field="estado"
      headerText="Estado"
      width="100"
      template={(props) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {props.estado === 'pendiente' ? (
          <TabWithIcon color="#FEC90F" />
        ) : props.estado === 'aprobado' ? (
          <TabWithIcon color="#8BE78B"  />
        ) : props.estado === 'cancelado' ? (
          <TabWithIcon color="red" />
        ) : (
          props.estado
        )}
          </div>
        );
      }}
    />

    {/* Total Cotización */}
    <ColumnDirective field="total_cotizacion" headerText="Valor" width="150" format="C2" />

    {/* Ubicación */}
    <ColumnDirective field="solicitante_comuna" headerText="Ubicación" width="200" />

    {/* Dirección de Instalación */}
    <ColumnDirective field="direccion_instalacion" headerText="Dirección" width="250" />
  </ColumnsDirective>
  <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
</GridComponent>

      )}
    </div>
  );
};

export default Cotizaciones;
