import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { Header, DetalleStock, NuevoStock } from '../components';
import { Tabs, Tab } from '@mui/material';
import axios from 'axios';

// Función para el renderizado del indicador de stock
const getStockColor = (stockValue) => {
  let color;
  
  if (stockValue === 0) {
    color = 'gray';
  } else if (stockValue <= 5) {
    color = 'red';
  } else if (stockValue > 5 && stockValue <= 15) {
    color = 'orange';
  } else {
    color = 'green';
  }

  return color;
};

const stockIndicator = (props) => {
  const color = getStockColor(parseInt(props.stockValue, 10));

  return (
    <div className="flex justify-center items-center" style={{ width: '100%', height: '100%' }}>
      <div style={{ width: '60px', height: '10px', backgroundColor: color, borderRadius: '5px' }}></div>
    </div>
  );
};

const Stock = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [DetalleStockVisible, setDetalleStockVisible] = useState(false);
  const [gridKey, setGridKey] = useState(0);
  const [nuevoStockVisible, setNuevoStockVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  // Nuevo estado para manejar errores

  const fetchStockData = async () => {
    try {
      setIsLoading(true);
      setError(null); // Reinicia cualquier error previo
      console.log('Backend URL:', process.env.REACT_APP_BACKEND_URL);
      const token = localStorage.getItem('access_token');

      if (!token) {
        setError('No se ha encontrado el token de autenticación. Por favor, inicia sesión.');
        return;
      }

      let response;
      switch (selectedTab) {
        case 0:
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/productos/laminas/`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setSelectedCategory('lamina');
          break;
        case 1:
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/productos/laminas/`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setSelectedCategory('lamina');
          break;
        case 2:
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/productos/herramientas/`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setSelectedCategory('herramienta');
          break;
        case 3:
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/productos/vehiculos/`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setSelectedCategory('vehiculo');
          break;
        default:
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/productos/laminas/?tipo_stock=bodega`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setSelectedCategory('lamina');
      }
      if (response && response.data) {
        filterData(response.data, selectedTab);
      } else {
        setError('No se recibieron datos del servidor.');
      }
    } catch (error) {
      setError('Error al cargar los datos de stock.');
      console.error('Error fetching stock data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const filterData = (productosData, tabIndex) => {
    let currentColumns = [];
    switch (tabIndex) {
      case 0: // Stock Bodega
        currentColumns = [
          { field: 'codigo', headerText: 'Código', width: '150' },
          { field: 'rendimiento', headerText: 'Rendimiento m2', width: '150' },
          { field: 'nombre', headerText: 'Nombre', width: '200' },
          { field: 'cantidad_bodega', headerText: 'Unidades', width: '100' }, // Aquí se utiliza cantidad_bodega
          { field: 'preciom2', headerText: 'Precio m2', width: '150', format: 'C2' },
          { field: 'cantidad_bodega', headerText: 'Indicador Stock', width: '150', template: (props) => stockIndicator({ stockValue: props.cantidad_bodega }) },
        ];
        break;
      case 1: // Stock Auto
        currentColumns = [
          { field: 'codigo', headerText: 'Código', width: '150' },
          { field: 'rendimiento', headerText: 'Rendimiento m2', width: '150' },
          { field: 'nombre', headerText: 'Nombre', width: '200' },
          { field: 'cantidad_vehiculos', headerText: 'Unidades', width: '100' }, // Aquí se utiliza cantidad_vehiculos
          { field: 'preciom2', headerText: 'Precio m2', width: '150', format: 'C2' },
          { field: 'cantidad_vehiculos', headerText: 'Indicador Stock', width: '150', template: (props) => stockIndicator({ stockValue: props.cantidad_vehiculos }) },
        ];
        break;
      case 2: // Herramientas
        currentColumns = [
          { field: 'codigo', headerText: 'Código', width: '150' },
          { field: 'nombre', headerText: 'Nombre', width: '200' },
          { field: 'unidades', headerText: 'Unidades', width: '100' },
          { field: 'unidades', headerText: 'Indicador Stock', width: '150', template: (props) => stockIndicator({ stockValue: props.unidades }) },
        ];
        break;
      case 3: // Vehículos
        currentColumns = [
          { field: 'codigo', headerText: 'Patente', width: '150' },
          { field: 'modelo', headerText: 'Modelo', width: '200' },
          { field: 'nombre', headerText: 'Nombre', width: '200' },
        ];
        break;
      default:
        currentColumns = [];
    }
  
    setFilteredData(productosData);
    setColumns(currentColumns);
    setGridKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    fetchStockData();
  }, [selectedTab]);

  const handleRowDoubleClick = (args) => {
    setSelectedStock(args.rowData);
    setDetalleStockVisible(true);
  };

  const closeDetalleStock = () => {
    setSelectedStock(null);
    setDetalleStockVisible(false);
  };

  const handleStockAdded = () => {
    fetchStockData();
  };

  // Barra de herramientas personalizada para el indicador de stock
  const toolbarOptions = [
    { text: 'Indicador de Stock:', align: 'Left' },
    { template: () => (
        <div className="flex items-center">
          <div className="flex items-center mr-4">
            <div style={{ width: '60px', height: '10px', backgroundColor: 'gray', borderRadius: '5px' }}></div>
            <span className="ml-2">Agotado</span>
          </div>
          <div className="flex items-center mr-4">
            <div style={{ width: '60px', height: '10px', backgroundColor: 'red', borderRadius: '5px' }}></div>
            <span className="ml-2">Bajo (1-5)</span>
          </div>
          <div className="flex items-center mr-4">
            <div style={{ width: '60px', height: '10px', backgroundColor: 'orange', borderRadius: '5px' }}></div>
            <span className="ml-2">Medio (6-15)</span>
          </div>
          <div className="flex items-center">
            <div style={{ width: '60px', height: '10px', backgroundColor: 'green', borderRadius: '5px' }}></div>
            <span className="ml-2">Alto (más de 15)</span>
          </div>
        </div>
      ), align: 'Left'
    },
    'Search'
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Stock" />

      <div className="flex justify-between items-center">
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          aria-label="stock tabs"
          sx={{
            '.MuiTab-root': { '&:hover': { color: '#FFa800' }, '&.Mui-selected': { color: '#FFa800' } },
            '.MuiTabs-indicator': { backgroundColor: '#FFa800' },
          }}
        >
          <Tab label="Stock Bodega" />
          <Tab label="Stock Auto" />
          <Tab label="Herramientas" />
          <Tab label="Vehículo" />
        </Tabs>

        <button
          type="button"
          onClick={() => setNuevoStockVisible(true)}
          style={{ backgroundColor: '#1fb519', color: 'white', padding: '8px 16px', borderRadius: '8px', fontWeight: 'lighter', border: 'none', cursor: 'pointer' }}
        >
          + Nuevo Stock
        </button>
      </div>

      {isLoading ? (
        <p>Cargando datos...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <GridComponent
          key={gridKey}
          dataSource={filteredData}
          enableHover={false}
          allowPaging
          pageSettings={{ pageCount: 5 }}
          selectionSettings={{ persistSelection: true }}
          toolbar={toolbarOptions}
          editSettings={{ allowDeleting: true, allowEditing: true }}
          allowSorting
          className="mt-4"
          recordDoubleClick={handleRowDoubleClick}
        >
          <ColumnsDirective>
            {columns.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
        </GridComponent>
      )}

      {DetalleStockVisible && selectedStock && (
        <DetalleStock stock={selectedStock} categoria={selectedCategory} onClose={closeDetalleStock} tabIndex={selectedTab}/>
      )}

      {nuevoStockVisible && <NuevoStock onClose={() => setNuevoStockVisible(false)} onStockAdded={handleStockAdded} />}
    </div>
  );
};

export default Stock;
