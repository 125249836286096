import React, { useState, useRef, useEffect } from 'react'; 
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import Confirmacion from './Confirmacion';
import axios from 'axios';
import { RiDeleteBinLine } from 'react-icons/ri'; // Importamos el ícono de eliminación

const NavButton = ({ title, customFunc, icon, color }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      {icon}
    </button>
  </TooltipComponent>
);

const Orden = ({ onClose, refreshOrdenes }) => {
  // Función para cerrar tanto Confirmacion como Cotizacion.jsx
  const handleSuccessfulSubmit = () => {
    setShowConfirm(false); // Cierra Confirmacion
    onClose();  // Cierra Cotizacion.jsx
    refreshOrdenes();  // Refresca la lista de cotizaciones
  };
  const [clientSaved, setClientSaved] = useState(false);
  const [error, setError] = useState(null); // Agregamos estado para manejar los errores
  const { currentColor } = useStateContext();
  const [userType, setUserType] = useState('');
  const [UsuarioDefinido, setUsuarioDefinido] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [userSelected, setUserSelected] = useState(false);
  const [serviceTypeVisible, setServiceTypeVisible] = useState(false);
  const [serviceTypeSelected, setServiceTypeSelected] = useState(false);
  const [showRetiroLaminaCheckbox, setShowRetiroLaminaCheckbox] = useState(false);
  const [showMontoInput, setShowMontoInput] = useState(false);
  const [metrosRetirar, setMetrosRetirar] = useState('');
  const [montoDistancia, setMontoDistancia] = useState('0');
  const [useSolicitanteData, setUseSolicitanteData] = useState(null);
  const [direccionInstalacion, setDireccionInstalacion] = useState('');
  const [showDificultadMontoInput, setShowDificultadMontoInput] = useState(false);
  const [dificultadMonto, setDificultadMonto] = useState('0');
  const [showOtroTipoPropiedad, setShowOtroTipoPropiedad] = useState(false);
  const [otroTipoPropiedad, setOtroTipoPropiedad] = useState('Casa');
  const [showMontoDistanciaInput, setShowMontoDistanciaInput] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  const [existingUserSearch, setExistingUserSearch] = useState('');
  const [tipoDificultad, setTipoDificultad] = useState('normal'); // Nuevo estado para tipo de dificultad
  const [metodoPago, setMetodoPago] = useState('debito'); // Valor por defecto
  const [notasAdicionales, setNotasAdicionales] = useState(''); // Estado para almacenar las notas adicionales
  const [totalCotizacion, setTotalCotizacion] = useState({
    neto: 0,
    iva: 0,
    totalConIva: 0,
  });

  const [retiroLaminasSeleccionado, setRetiroLaminasSeleccionado] = useState(null); 
  const [distanciaSeleccionada, setDistanciaSeleccionada] = useState(null); // null significa que no se ha seleccionado ninguna opción
  const [selectedUser, setSelectedUser] = useState(null); // Estado para almacenar el usuario seleccionado

  const [suggestions, setSuggestions] = useState([]);
  const [direccionSolicitante, setDireccionSolicitante] = useState('');
  const [items, setItems] = useState([{ lamina: '', alto: '', ancho: '', m2: '', total: '' }]);
  const [laminas, setLaminas] = useState([]); // Estado para almacenar las láminas
  // Referencias para el scroll
  const errorRef = useRef(null);
  const bottomRef = useRef(null);
  const handleDeleteItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1); // Elimina el ítem correspondiente
    setItems(newItems);
  };
  const clearTotalCotizacion = () => {
    setItems([{ lamina: '', alto: '', ancho: '', m2: '', total: '' }]); // Reiniciar los ítems
    setDificultadMonto('0'); // Reiniciar monto de dificultad
    setMetrosRetirar('0'); // Reiniciar metros a retirar
    setMontoDistancia('0'); // Reiniciar monto por distancia
    setDescuentoActivo(false); // Desactivar descuento
    setDescuento(''); // Limpiar el valor del descuento
  };
  const [newUserDetails, setNewUserDetails] = useState({
    nombre: '',
    apellido: '',
    region: '',
    comuna: '',
    direccion: '',
    email: '',
    whatsapp: '',
    notas: '',  // Campo adicional para notas
  });

  useEffect(() => {
    // Función para obtener las láminas desde la API
    const fetchLaminas = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          console.error('No se ha encontrado el token de autenticación.');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/productos/laminas/`, {
          headers: {
            Authorization: `Bearer ${token}`, // Asegura que estás enviando el token si es necesario
          },
        });
              // Filtrar láminas que tengan cantidad_bodega mayor a 0
      const laminasConStock = response.data.filter(lamina => lamina.cantidad_bodega > 0);


      setLaminas(laminasConStock); // Almacenar solo las láminas con stock
      } catch (error) {
        console.error('Error fetching laminas:', error);
      }
    };

    fetchLaminas(); // Llamar a la función para obtener las láminas
  }, []);
  // Efecto para hacer scroll al mostrar el error
  useEffect(() => {
    if (error) {
      // Si hay un error, desplazarse hasta el fondo para mostrar el mensaje de error y el botón
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]);

  const [descuentoActivo, setDescuentoActivo] = useState(false); // Estado para el checkbox de descuento
  const [descuento, setDescuento] = useState(''); // Estado para el valor del descuento

  // Función para calcular el total general, aplicando el descuento si está activo
  useEffect(() => {
    // Función para calcular el total general, aplicando el descuento si está activo
    const calcularTotalCotizacion = () => {
      // Calcular el total basado en los ítems de láminas
      const totalItems = items.reduce((acc, item) => {
        const totalItem = parseFloat(item.m2) * (item.precio || 30000) || 0; // Usamos el precio almacenado en cada ítem
        return acc + totalItem;
      }, 0);
  
      const totalDificultad = parseFloat(dificultadMonto) || 0;
      const totalRetiroLamina = showMontoInput ? (parseFloat(metrosRetirar) * 10000) || 0 : 0;
      const totalDistancia = showMontoDistanciaInput ? parseFloat(montoDistancia) || 0 : 0;
  
      let totalNeto = totalItems + totalDificultad + totalRetiroLamina + totalDistancia;
  
      // Aplicar descuento si está activo
      if (descuentoActivo && descuento) {
        const descuentoValor = (totalNeto * parseFloat(descuento)) / 100;
        totalNeto -= descuentoValor;
      }
  
      // Calcular IVA (19%)
      const iva = totalNeto * 0.19;
  
      // Calcular el total con IVA
      const totalConIva = totalNeto + iva;
      // Redondear los valores a enteros
      const netoRedondeado = Math.round(totalNeto);
      const ivaRedondeado = Math.round(iva);
      const totalConIvaRedondeado = Math.round(totalConIva);
  
      // Actualizar el estado con los valores
      setTotalCotizacion({
        neto: netoRedondeado,
        iva: ivaRedondeado,
        totalConIva: totalConIvaRedondeado,
      });
    };
  
    calcularTotalCotizacion(); // Llama a la función para calcular el total cuando se cargue el componente
  }, [items, descuentoActivo, descuento, dificultadMonto, metrosRetirar, montoDistancia, showMontoInput, showMontoDistanciaInput]);

  const handleInputChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;

    // Si se está cambiando la lámina, obtener el precio por m² correspondiente
    if (field === 'lamina') {
      const selectedLamina = laminas.find(lamina => lamina.nombre === value);
      if (selectedLamina) {
        newItems[index].precio = selectedLamina.preciom2; // Guardamos el precio por m² de la lámina seleccionada
      }
    }

    // Si se están cambiando el alto o ancho, recalcula el M2 y el total
    if (field === 'alto' || field === 'ancho') {
      const alto = parseFloat(newItems[index].alto) || 0;
      const ancho = parseFloat(newItems[index].ancho) || 0;
      const m2 = (alto * ancho).toFixed(2);
      newItems[index].m2 = m2;
  
      // Usamos el precio de la lámina seleccionada, si está disponible
      const precioPorM2 = newItems[index].precio ? parseFloat(newItems[index].precio) : 30000; // Si no hay precio, usamos 30,000 como valor por defecto
      newItems[index].total = `${(m2 * precioPorM2).toFixed(0)}`; // Calcula el total según el m² y el precio de la lámina
    }
  
    setItems(newItems);
  };

  const handleAddItem = () => {
    setItems([...items, { lamina: '', alto: '', ancho: '', m2: '', total: '' }]);
  };

  const handleDescuentoChange = (e) => {
    const value = e.target.value;
    // Restringir el valor del descuento entre 1 y 60
    if (value >= 1 && value <= 60) {
      setDescuento(value);
    } else if (value === '') {
      setDescuento('');
    }
  };

  // Manejar la selección del usuario de las sugerencias
  const handleUserSelection = (user) => {
    setSelectedUser({
      id: user.id,
      nombre: user.nombre,
      apellido: user.apellido,
      direccion: user.direccion,
      whatsapp: user.whatsapp,
    });
    // Actualiza el valor del campo de búsqueda con el nombre y apellido del usuario seleccionado
    setExistingUserSearch(`${user.nombre} ${user.apellido}`);

    setUsuarioDefinido(true);
    setDireccionSolicitante(user.direccion); // Guarda la dirección del usuario seleccionado
    setSuggestions([]); // Limpia las sugerencias
    setServiceTypeVisible(true); // Ahora mostramos las opciones de servicio cuando se selecciona un usuario
  };

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Función para manejar la búsqueda de usuarios existentes con sugerencias
  const handleExistingUserSearchChange = async (e) => {
    const value = e.target.value;
    setExistingUserSearch(value);

    if (value.trim().length >= 4) {
      try {
        const token = localStorage.getItem('access_token');

        if (!token) {
          console.error('No se ha encontrado el token de autenticación.');
          return;
        }

        const response = await axios.get(`${backendUrl}/clientes/clientes/buscar/?query=${value}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Añadir el token al encabezado
          },
        });

        setSuggestions(response.data.slice(0, 4)); // Limitar sugerencias a 4 resultados
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setServiceTypeVisible(false);
      setSuggestions([]); // Limpiar sugerencias si el input está vacío
    }
  };

  

  const handleUserTypeChange = (type) => {
    setUserType(type);
    setUserSelected(true);
    setServiceTypeVisible(false);
    setServiceTypeSelected(false);
    setShowRetiroLaminaCheckbox(false);
    setShowMontoInput(false);
    setShowDificultadMontoInput(false);
    setShowOtroTipoPropiedad(false);
    setShowMontoDistanciaInput(false);
    setDistanciaSeleccionada(null); // Resetear la selección de distancia
    setRetiroLaminasSeleccionado(null); // Resetear la selección de retiro de láminas
    setExistingUserSearch(''); // Limpiar el campo de búsqueda de usuario
    setSuggestions([]); // Limpiar las sugerencias
    setNewUserDetails({
      nombre: '',
      apellido: '',
      region: '',
      comuna: '',
      direccion: '',
      email: '',
      whatsapp: '',
      notas: '',  // Campo adicional para notas
    });
    setClientSaved(false)
    setUseSolicitanteData(null); // Limpiar la variable para que no mantenga los datos del solicitante
    setDireccionInstalacion(''); // Limpiar la dirección de instalación
    setUsuarioDefinido(false); // Restablecer si se seleccionó un usuario
    setItems([{ lamina: '', alto: '', ancho: '', m2: '', total: '' }]); // Resetear los ítems de cotización
    setDescuentoActivo(false); // Desactivar el descuento
    setDescuento(''); // Limpiar el valor del descuento
    setMetrosRetirar('0'); // Resetear metros a retirar
    setMontoDistancia('0'); // Resetear monto de distancia
    setOtroTipoPropiedad('Casa'); // Resetear otro tipo de propiedad
    setError(''); // Limpiar los errores
  };

  const handleNewUserDetailChange = (e) => {
    const { name, value } = e.target;
    setNewUserDetails({
      ...newUserDetails,
      [name]: value,
    });
  };

  const handleServiceTypeChange = (e) => {
    const selectedService = e.target.value;
    setSelectedService(selectedService);
    setServiceTypeSelected(true);
    setShowRetiroLaminaCheckbox(false);
    setShowMontoInput(false);
    clearTotalCotizacion(); // Limpiar el total de la cotización cuando cambia el tipo de servicio
    setShowDificultadMontoInput(false);
    setShowOtroTipoPropiedad(false);
    setShowMontoDistanciaInput(false);
    setDistanciaSeleccionada(null); // Resetear la selección de distancia
    setRetiroLaminasSeleccionado(null); // Resetear la selección de retiro de láminas
    setOtroTipoPropiedad('Casa'); // Resetear otro tipo de propiedad
    if (selectedService === 'instalacion') {
      setShowRetiroLaminaCheckbox(true);
    } else {
      setShowRetiroLaminaCheckbox(false);
    }
    if (selectedService === 'visita_tecnica') {
      clearItems();
      clearDiscount();
    }
  };

  // Nueva función para desactivar el descuento
  const clearDiscount = () => {
    setDescuentoActivo(false); // Desactiva el checkbox de descuento
    setDescuento(''); // Limpia el valor del descuento
  };
  // Nueva función para limpiar todos los ítems
  const clearItems = () => {
    setItems([{ lamina: '', alto: '', ancho: '', m2: '', total: '' }]); // Restablecemos a un ítem vacío
  };
  const handleRetiroLaminaChange = (e) => {
    const selectedOption = e.target.value;
    setRetiroLaminasSeleccionado(selectedOption); // Actualizamos si se seleccionó "Sí" o "No"
    if (selectedOption === 'Si') {
      setShowMontoInput(true);
    } else {
      setShowMontoInput(false);
    }
    setMetrosRetirar('0');
  };

  const handleDistanciaChange = (e) => {
    const selectedOption = e.target.value;
    setDistanciaSeleccionada(selectedOption); // Actualiza si se seleccionó "Sí" o "No"
    if (selectedOption === 'Si') {
      setShowMontoDistanciaInput(true);
    } else {
      setShowMontoDistanciaInput(false);
    }
    setMontoDistancia('0');
  };

  const handleUseSolicitanteDataChange = (e) => {
    const selectedOption = e.target.value;
    setUseSolicitanteData(selectedOption === 'Si');
    if (selectedOption === 'Si') {
      setDireccionInstalacion(direccionSolicitante);
    } else {
      setDireccionInstalacion('');
    }
  };

  const handleDificultadChange = (e) => {
    const selectedOption = e.target.value;
    setTipoDificultad(selectedOption); // Actualizamos el tipo de dificultad
    setShowDificultadMontoInput(selectedOption !== 'normal');
    setDificultadMonto('0'); // Reiniciamos el monto de dificultad si es necesario
  };

  const handleTipoPropiedadChange = (e) => {
    const selectedOption = e.target.value;
    setShowOtroTipoPropiedad(selectedOption === 'Otros');
    if (selectedOption !== 'Otros') {
      setOtroTipoPropiedad(selectedOption);  // Guarda el valor seleccionado (Casa, Departamento, etc.)
    }
  };

  const validateForm = () => {
    if (!userType) {
      setError('Debe seleccionar un tipo de usuario.');
      return false;
    }

    if (!UsuarioDefinido) {
      setError('Debes seleccionar un usuario para cotizar.');
      return false;
    }

    if (!selectedService) {
      setError('Debe seleccionar un tipo de servicio.');
      return false;
    }

    if (direccionInstalacion === '') {
      setError('Falta la dirección del solicitante.');
      return false;
    }

    if (selectedService === 'instalacion') {

      if (showDificultadMontoInput && parseFloat(dificultadMonto) <= 0) {
        setError('Debe ingresar un monto de dificultad mayor a 0.');
        return false;
      }

      if (retiroLaminasSeleccionado === null) {
        setError('Debe seleccionar si requiere o no retiro de láminas.');
        return;
      }
    


      if (showMontoInput && parseFloat(metrosRetirar) <= 0) {
        setError('Debe ingresar los m² a retirar superiores a 0.');
        return false;
      }

    
      // Verificar si no se ha seleccionado ninguna opción
      if (distanciaSeleccionada === null) {
        setError('Debe seleccionar si se cobra o no por distancia.');
        return false;
      }

      if (showMontoDistanciaInput && parseFloat(montoDistancia) <= 0) {
        setError('Debe ingresar un monto válido por distancia superior a 0.');
        return false;
      }

      if (showOtroTipoPropiedad && !otroTipoPropiedad) {
        setError('Debe ingresar el nombre del tipo de propiedad.');
        return false;
      }

    }

    if (selectedService === 'instalacion' || selectedService === 'envio'){
      const hasValidItem = items.some(item => item.lamina !== '' && parseFloat(item.total.replace(/[^0-9.-]+/g, "")) > 0);
      if (!hasValidItem) {
        setError('Debe haber al menos un ítem de lámina seleccionado con un total mayor a 0.');
        return false;
      }
    }

    setError(null); // Limpiamos el error si todo está bien
    return true;
  };

  const handleEnviarClick = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setShowConfirm(true);
  };

  const handleSaveNewClient = async () => {
    // Validar que todos los campos estén completos
    if (
      !newUserDetails.nombre.trim() ||
      !newUserDetails.apellido.trim() ||
      !newUserDetails.region.trim() ||
      !newUserDetails.comuna.trim() ||
      !newUserDetails.direccion.trim() ||
      !newUserDetails.email.trim() ||
      !newUserDetails.whatsapp.trim()
    ) {
      setError('Debe completar todos los campos antes de guardar el cliente.');
      return; // Salir si faltan campos
    }
    setError(null); // Limpiar errores anteriores
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/clientes/crear/`,
        newUserDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const createdUser = response.data;
      console.log('Cliente creado con éxito:', response.data);
      // Actualizamos el estado con el nuevo usuario
      setSelectedUser({
        id: createdUser.id,
        nombre: createdUser.nombre,
        apellido: createdUser.apellido,
        direccion: createdUser.direccion,
        whatsapp: createdUser.whatsapp,
      });
      // Manejar la respuesta después de crear el cliente
      setServiceTypeVisible(true);
      setUsuarioDefinido(true);
      setDireccionSolicitante(createdUser.direccion); // Guarda la dirección del usuario seleccionado
      setClientSaved(true);  // Oculta los botones al guardar el cliente
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.error;

        if (errorMessage.includes('email')) {
          setError('El correo electrónico ya está registrado. Intente con otro.');
        } else if (errorMessage.includes('whatsapp')) {
          setError('El número de WhatsApp ya está registrado. Intente con otro.');
        } else {
          setError('Ocurrió un error al crear el cliente. Intente nuevamente.');
        }
      } else {
        setError('Ocurrió un error al crear el cliente. Intente nuevamente.');
      }
      setServiceTypeVisible(false);
    }
  };

  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
        <div className="responsive-div" style={{ backgroundColor: 'white' }}>
          <div className="flex justify-between items-center mb-4">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Nueva Orden de venta</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
            />
          </div>

          <div className="mb-4">
            <p className="font-semibold text-lg">Orden de Venta</p>
          </div>
          <div className="overflow-y-auto" style={{ maxHeight: '80vh' }}>
            {/* Selección entre nuevo usuario o existente */}
            <div className="mb-4">
              <label className="block text-gray-700">Tipo de Usuario</label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="userType"
                    value="existente"
                    className="mr-2"
                    checked={userType === 'existente'}
                    onChange={() => handleUserTypeChange('existente')}
                  />
                  Existente
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="userType"
                    value="nuevo"
                    className="mr-2"
                    checked={userType === 'nuevo'}
                    onChange={() => handleUserTypeChange('nuevo')}
                  />
                  Nuevo
                </label>
              </div>
            </div>

            {/* Campo de búsqueda de usuarios existentes */}
            {userSelected && userType === 'existente' && (
              <div className="mb-4">
                <label className="block text-gray-700">Buscar Usuario</label>
                <input
                  type="text"
                  className="w-full md:w-1/2 lg:w-1/2 p-2 border rounded"
                  placeholder="Buscar por nombre o email"
                  value={existingUserSearch}
                  onChange={handleExistingUserSearchChange}
                />

                {/* Mostrar las sugerencias debajo del input */}
                {suggestions.length > 0 && (
                  <ul className="bg-white border rounded-md mt-2">
                    {suggestions.map((user, index) => (
                      <li
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleUserSelection(user)} // Usamos handleUserSelection al seleccionar
                        
                      >
                        {user.nombre} {user.apellido} - {user.email}
                        
                      </li>
                      

                    ))}
                    
                  </ul>
                  
                )}
              </div>
            )}

            {/* Formulario para nuevo usuario */}
            {!clientSaved  && userSelected && userType === 'nuevo' && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-gray-700">Nombre</label>
                  <input
                    type="text"
                    name="nombre"
                    className="w-full p-2 border rounded"
                    value={newUserDetails.nombre}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Apellido</label>
                  <input
                    type="text"
                    name="apellido"
                    className="w-full p-2 border rounded"
                    value={newUserDetails.apellido}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Región</label>
                  <input
                    type="text"
                    name="region"
                    className="w-full p-2 border rounded"
                    value={newUserDetails.region}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Comuna</label>
                  <input
                    type="text"
                    name="comuna"
                    className="w-full p-2 border rounded"
                    value={newUserDetails.comuna}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Dirección</label>
                  <input
                    type="text"
                    name="direccion"
                    className="w-full p-2 border rounded"
                    value={newUserDetails.direccion}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="w-full p-2 border rounded"
                    value={newUserDetails.email}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">WhatsApp o teléfono</label>
                  <input
                    type="text"
                    name="whatsapp"
                    className="w-full md:w-1/2 lg:w-1/2 p-2 border rounded"
                    value={newUserDetails.whatsapp}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Notas</label>
                  <input
                    type="text"
                    name="notas"
                    className="w-full p-2 border rounded"
                    value={newUserDetails.notas}
                    onChange={handleNewUserDetailChange}
                  />
                </div>
                {userSelected && userType === 'nuevo' && (
                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      className="p-2 rounded text-white"
                      onClick={handleSaveNewClient}
                      style={{ backgroundColor: '#FFa800' }}
                    >
                      Guardar Cliente
                    </button>
                  </div>
                )}
                <div>
                  {/* Mostrar el mensaje de error */}
                  {error && !UsuarioDefinido && <p className="text-red-500 mb-4">{error}</p>}
                  {/* El resto de tu componente */}
                </div>
              </div>
            )}

            {
              clientSaved && (
                <div className="mb-4">
                  <div className="flex space-x-4">

                  <label className="font-semibold block text-gray-700">Cliente: {`${selectedUser?.nombre || ''} ${selectedUser?.apellido || ''}`}</label>
                  </div> 
                </div>
              )
            }

            {/* Tipo de servicio */}
            {serviceTypeVisible && (
              <div className="mb-4">
                <label className="block text-gray-700">Tipo de servicio</label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="servicio"
                      value="instalacion"
                      className="mr-2"
                      onChange={(e) => handleServiceTypeChange(e)}
                    />
                    Instalación
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="servicio"
                      value="envio"
                      className="mr-2"
                      onChange={(e) => handleServiceTypeChange(e)}
                    />
                    Envío de material
                  </label>
                </div>

                <div className="mt-4">
                  <label className="block text-gray-700">Dirección: ¿Utilizar los mismos datos que el solicitante?</label>
                  <div className="flex space-x-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="usarDatosSolicitante"
                        value="Si"
                        className="mr-2"
                        checked={useSolicitanteData === true}
                        onChange={handleUseSolicitanteDataChange}
                      />
                      Sí
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="usarDatosSolicitante"
                        value="No"
                        className="mr-2"
                        checked={useSolicitanteData === false}
                        onChange={handleUseSolicitanteDataChange}
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            )}

            {useSolicitanteData === true && (
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={direccionSolicitante}
                disabled
              />
            )}
            {useSolicitanteData === false && (
              <div className="flex space-x-4 mb-4">
                <input
                  type="text"
                  className="w-full md:w-1/2 lg:w-1200 p-2 border rounded"
                  placeholder="Ingrese la dirección de instalación/Envío/Visita"
                  value={direccionInstalacion}
                  onChange={(e) => setDireccionInstalacion(e.target.value)}
                />
              </div>
            )}

            {serviceTypeVisible && showRetiroLaminaCheckbox && (
              <div className="mb-4">

                <div className="flex space-x-4 mb-4">
                  <div className="w-1/2">
                    <label className="block text-gray-700">Dificultad</label>
                    <select className="border p-2 rounded" onChange={handleDificultadChange}>
                      <option value="normal">Normal</option>
                      <option value="categoria1">Categoría 1</option>
                      <option value="categoria2">Categoría 2</option>
                      <option value="categoria3">Categoría 3</option>
                    </select>
                  </div>
                  {showDificultadMontoInput && (
                    <div className="w-1/2">
                      <label className="block text-gray-700">Monto Dificultad</label>
                      <input
                        type="text"
                        className="border p-2 rounded"
                        placeholder="Monto"
                        value={dificultadMonto}
                        onChange={(e) => setDificultadMonto(e.target.value)}
                      />
                    </div>
                  )}

                </div>

                <div className="mb-4">
                  <div className="flex space-x-4 mb-4">
                    <div className="w-1/2">
                      <label className="block text-gray-700">¿Requiere retiro de láminas?</label>
                      <div className="flex space-x-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="retiro"
                            value="Si"
                            className="mr-2"
                            onChange={handleRetiroLaminaChange}
                          />
                          Sí
                          {showMontoInput && (
                            <input
                              type="text"
                              className="ml-2 p-2 border rounded"
                              placeholder="m² a retirar"
                              value={metrosRetirar}  // Usamos metrosRetirar aquí
                              onChange={(e) => setMetrosRetirar(e.target.value)}  // Actualizamos metrosRetirar
                            />
                          )}
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="retiro"
                            value="No"
                            className="mr-2"
                            onChange={handleRetiroLaminaChange}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700">¿Cobro por distancia?</label>
                    <div className="flex space-x-4">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="distancia"
                          value="Si"
                          className="mr-2"
                          onChange={handleDistanciaChange}
                        />
                        Sí
                        {showMontoDistanciaInput && (
                          <input
                            type="text"
                            className="ml-2 p-2 border rounded"
                            placeholder="Monto"
                            value={montoDistancia}  // Usamos montoDistancia aquí
                            onChange={(e) => setMontoDistancia(e.target.value)}  // Actualizamos montoDistancia
                          />
                        )}
                      </label>
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="distancia"
                          value="No"
                          className="mr-2"
                          onChange={handleDistanciaChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-4 flex space-x-4">
                  <div className="w-1/2">
                    <label className="block text-gray-700">Tipo Propiedad</label>
                    <select className="border p-2 rounded" onChange={handleTipoPropiedadChange}>
                      <option value="Casa">Casa</option>
                      <option value="Departamento">Departamento</option>
                      <option value="Oficina">Oficina</option>
                      <option value="Otros">Otros</option>
                    </select>
                  </div>

                  {showOtroTipoPropiedad && (
                    <div className="w-1/2">
                      <label className="block text-gray-700">Nombre tipo de Propiedad</label>
                      <input
                        type="text"
                        className="border p-2 rounded"
                        placeholder="Empresa, hotel, etc."
                        value={otroTipoPropiedad}
                        onChange={(e) => setOtroTipoPropiedad(e.target.value)}
                      />
                    </div>
                  )}
                </div>

              </div>
            )}

            {serviceTypeSelected && (selectedService === 'instalacion' || selectedService === 'envio') && (
              <div>
                {/* Items de cotización */}
                <div className="mb-4">
                  <p className="font-semibold text-gray-700">Items orden de venta</p>
                  {items.map((item, index) => (
                    <div key={index} className="grid grid-cols-8 gap-2 mb-4"> {/* Cambié a 6 columnas para incluir el botón de borrar */}
                      <select
                        className="border p-2 rounded col-span-3"
                        value={item.lamina}
                        onChange={(e) => handleInputChange(index, 'lamina', e.target.value)}
                      >
                        <option value="">Selecciona una lámina</option>
                        {laminas.map((lamina) => (
                          <option key={lamina.id} value={lamina.nombre}>
                            {lamina.nombre} - ({lamina.codigo})
                          </option>
                        ))}
                      </select>
                      <input
                        type="text"
                        className="border p-2 rounded text-center"
                        placeholder="Alto"
                        value={item.alto}
                        onChange={(e) => handleInputChange(index, 'alto', e.target.value)}
                      />
                      <input
                        type="text"
                        className="border p-2 rounded text-center"
                        placeholder="Ancho"
                        value={item.ancho}
                        onChange={(e) => handleInputChange(index, 'ancho', e.target.value)}
                      />
                      <input
                        type="text"
                        className="border p-2 rounded text-center"
                        placeholder="M2"
                        value={item.m2}
                        readOnly
                      />
                      <p className="self-center">{item.total || '$0'}</p>
                      <button
                        type="button"
                        onClick={() => handleDeleteItem(index)} // Función para borrar el ítem
                        className="text-red-500"
                      >
                        <RiDeleteBinLine size={24} />
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddItem}
                    className="text-yellow-500"
                    style={{ color: '#FFa800' }}
                  >
                    + Añadir Item
                  </button>
                  {showDificultadMontoInput && (
                    <div className="flex justify-between mt-2">
                      <p className="text-sm text-gray-500">Monto por Dificultad:</p>
                      <p className="text-sm">{`$${parseFloat(dificultadMonto).toLocaleString()}`}</p>
                    </div>
                  )}
                    {showMontoInput && (
                      <div className="flex justify-between mt-2">
                        <p className="text-sm text-gray-500">Monto por Retiro de Láminas (m²):</p>
                        <p className="text-sm">{`$${(parseFloat(metrosRetirar) * 10000).toLocaleString()}`}</p>
                      </div>
                    )}
                    
                    {showMontoDistanciaInput && (
                      <div className="flex justify-between mt-2">
                        <p className="text-sm text-gray-500">Cobro por Distancia:</p>
                        <p className="text-sm">{`$${parseFloat(montoDistancia).toLocaleString()}`}</p>
                      </div>
                    )}
                </div>

                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={descuentoActivo}
                    onChange={() => {
                      setDescuentoActivo(!descuentoActivo);
                      if (descuentoActivo) {
                        setDescuento(0); // Si el checkbox se desmarca, poner descuento en 0
                      }
                     else  {
                      setDescuento(1);
                    }
                    }}
                  />
                  Descuento
                </label>

                {/* Input para el porcentaje de descuento */}
                {descuentoActivo && (
                  <input
                    type="number"
                    className="ml-2 p-2 border rounded w-20"
                    placeholder="10%"
                    value={descuento}
                    onChange={handleDescuentoChange}
                    min="1"
                    max="60"
                  />
                )}

              <div className="mb-4">
                <label className="block text-gray-700">Método de Pago</label>
                <select
                  value={metodoPago}
                  onChange={(e) => setMetodoPago(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="debito">Débito</option>
                  <option value="credito">Crédito</option>
                  <option value="transferencia">Transferencia</option>
                  <option value="efectivo">Efectivo</option>
                </select>
              </div>
                {/* Mostrar el total con el descuento aplicado */}
                {/* Mostrar el total con el descuento aplicado */}
                <div className="mb-4">
                  <div className="flex justify-between mt-2">
                    <p className="font-semibold text-gray-700">Monto Neto</p>
                    <p className="font-semibold">{`$${totalCotizacion.neto.toLocaleString()}`}</p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <p className="font-semibold text-gray-700">Monto IVA (19%)</p>
                    <p className="font-semibold">{`$${totalCotizacion.iva.toLocaleString()}`}</p>
                  </div>
                  <div className="flex justify-between mt-2">
                    <p className="font-semibold text-gray-700">Total final</p>
                    <p className="font-semibold">{`$${totalCotizacion.totalConIva.toLocaleString()}`}</p>
                  </div>
                </div>

              </div>
            )}

            {/* Nota adicional */}
            <div className="mb-4">
              <label className="block text-gray-700">Añade una nota adicional para el cliente</label>
              <textarea
                className="w-full p-2 border rounded"
                rows="3"
                placeholder="Añade una nota adicional para el cliente"
                value={notasAdicionales}  // Vinculamos el estado de las notas
                onChange={(e) => setNotasAdicionales(e.target.value)}  // Actualizamos el estado cuando el usuario escribe
              />
            </div>

            {/* Mostrar el error si existe */}
            {error && (
              <div ref={errorRef} className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            )}

            <div ref={bottomRef} className="flex justify-between mt-4">
              <button type="button" className="bg-gray-300 p-2 rounded text-gray-700" onClick={onClose}>Cerrar</button>
              <button type="submit" className="p-2 rounded text-white" onClick={handleEnviarClick} style={{ backgroundColor: '#FFa800' }}>Guardar</button>
            </div>
          </div>
        </div>
      </div>
      {showConfirm && (
        <Confirmacion
          onClose={() => setShowConfirm(false)}
          onSuccessfulSubmit={handleSuccessfulSubmit}  // Cerrar ambos modales cuando se envíe con éxito
          fechaCotizacion={new Date().toLocaleDateString('es-CL')}
          cliente={`${selectedUser?.nombre || ''} ${selectedUser?.apellido || ''}`}
          cliente_id={selectedUser?.id || ''}
          direccion={direccionInstalacion || ''}
          telefono={selectedUser?.whatsapp || ''}
          items={items}
          totalM2={items.reduce((acc, item) => acc + parseFloat(item.m2 || 0), 0).toFixed(2)}
          iva={`${totalCotizacion.iva.toLocaleString()}`}
          totalCotizacion={`${totalCotizacion.totalConIva}`}
          descuento={descuento}
          tipoInstalacion={selectedService}
          retiroLaminas={retiroLaminasSeleccionado === 'Si' ? 'Sí' : 'No'}
          metrosRetirar={retiroLaminasSeleccionado === 'Si' ? metrosRetirar || 0 : null}
          montoRetiro={retiroLaminasSeleccionado === 'Si' ? metrosRetirar * 10000 : 0}
          cobroDistancia={distanciaSeleccionada === 'Si'}
          montoDistancia={montoDistancia}
          tipoDificultad={tipoDificultad}
          montoDificultad={dificultadMonto > 0 ? dificultadMonto : 0}
          notasAdicionales={notasAdicionales}
          tipoPropiedad={otroTipoPropiedad}  // Aquí pasas el tipo de propiedad
          metodoPago={metodoPago}  // Este campo es específico para órdenes de venta
          tipo="orden"  // Especificar que es una orden de venta
        />
        )}
    </>
  );
};

export default Orden;
