import React, { useState, useEffect } from 'react';
import { RiCloseCircleLine } from "react-icons/ri";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios'; // Para hacer la petición
import html2pdf from 'html2pdf.js';
import '../DetalleCotizacion.css'; // Importa los estilos desde el archivo CSS
import moment from 'moment'; // Para manejar fechas

const NavButton = ({ title, customFunc, icon, color, size = 'xl', padding = 'p-3' }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={`relative text-${size} rounded-full ${padding} hover:bg-light-gray`}
    >
      {icon}
    </button>
  </TooltipComponent>
);

const DetalleCotizacion = ({ cotizacionId, onClose }) => {
  const { currentColor } = useStateContext();
  const [cotizacion, setCotizacion] = useState(null); // Estado para almacenar los detalles de la cotización
  const [cliente, setCliente] = useState(null); // Estado para almacenar los detalles del cliente
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores
  const [fechaCotizacion, setFechaCotizacion] = useState('');
  const [totalM2, setTotalM2] = useState(0);


  useEffect(() => {
    let isMounted = true; // Para controlar si el componente sigue montado
  
    const fetchCotizacion = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/${cotizacionId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        if (isMounted) {
          setCotizacion(response.data);
          // Formatear la fecha y guardarla
          const formattedDate = new Date(response.data.fecha_cotizacion).toLocaleDateString('es-CL', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          setFechaCotizacion(formattedDate);
    
          if (response.data.solicitante) {
            const clienteResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/clientes/clientes/${response.data.solicitante}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            setCliente(clienteResponse.data); // Asignar el objeto cliente directamente
          }
                  // Calcula el total de metros cuadrados sumando el campo m2 de cada detalle
        const totalMetros = response.data.detalles.reduce((total, item) => {
          return total + (parseFloat(item.m2) || 0); // Asegúrate de que m2 sea un número válido
        }, 0);

        setTotalM2(totalMetros); // Guarda el total de m² en el estado
          setLoading(false);
        }
      } catch (error) {
        console.error('Error al cargar los detalles de la cotización o cliente:', error);
        if (isMounted) {
          setError('No se pudo cargar la cotización o el cliente.');
          setLoading(false);
        }
      }
    };
  
    fetchCotizacion();
  
    return () => {
      isMounted = false;
    };
  }, [cotizacionId]);

  if (loading) {
    return <div>Cargando los detalles de la cotización...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const generatePDF = async () => {
    try {
      const response = await fetch('/PDFCotizacion.html');
      let templateHTML = await response.text();      
      const totalLaminas = cotizacion.detalles.reduce((total, item) => total + (parseFloat(item.total || 0)), 0);
      const montoDificultad = parseFloat(cotizacion.monto_dificultad) || 0;
      const montoRetiroLaminas = parseFloat(cotizacion.monto_retiro_laminas) || 0;
      const montoDistancia = parseFloat(cotizacion.monto_distancia) || 0;
      const montoNeto = totalLaminas + montoDificultad + montoRetiroLaminas + montoDistancia;
      const iva = Math.round(montoNeto * 0.19);
      let descuento = cotizacion.descuento > 0 ? montoNeto * (cotizacion.descuento / 100) : 0;

      templateHTML = templateHTML
        .replace('{{nombrePago}}', process.env.REACT_APP_NOMBRE_PAGO)
        .replace('{{rutPago}}', process.env.REACT_APP_RUT_PAGO)
        .replace('{{bancoPago}}', process.env.REACT_APP_BANCO_PAGO)
        .replace('{{tipoCuentaPago}}', process.env.REACT_APP_TIPO_CUENTA_PAGO)
        .replace('{{numeroCuentaPago}}', process.env.REACT_APP_NUMERO_CUENTA_PAGO)
        .replace('{{mailCuentaPago}}', process.env.REACT_APP_MAIL_CUENTA_PAGO)
        .replace('{{montoPorM2}}', totalLaminas.toLocaleString('es-CL'))
        .replace('{{dificultad}}', cotizacion.monto_dificultad.toLocaleString('es-CL'))
        .replace('{{retiroLaminas}}', cotizacion.monto_retiro_laminas.toLocaleString('es-CL'))
        .replace('{{distancia}}', cotizacion.monto_distancia.toLocaleString('es-CL'))
        .replace('{{montoNeto}}', montoNeto.toLocaleString('es-CL'))
        .replace('{{iva}}', iva.toLocaleString('es-CL'))
        .replace('{{descuento}}', descuento.toLocaleString('es-CL'))
        .replace('{{totalFinal}}', cotizacion.total_cotizacion.toLocaleString('es-CL'))
        .replace('{{cotizacionId}}', cotizacionId)
        .replace('{{fecha}}', fechaCotizacion)
        .replace('{{tipoInstalacion}}', cotizacion.tipo_instalacion)
        .replace('{{clienteNombre}}', cliente.nombre)
        .replace('{{clienteApellido}}', cliente.apellido)
        .replace('{{clienteWhatsApp}}', cliente.whatsapp)
        .replace('{{clienteEmail}}', cliente.email)
        .replace('{{direccionInstalacion}}', cotizacion.direccion_instalacion)
        .replace('{{clienteComuna}}', cliente.comuna);
        

      let detalleItems = '';
      cotizacion.detalles.forEach((item, index) => {
        detalleItems += `
          <tr>
            <td>${index + 1}</td>
            <td>${item.item}</td>
            <td>${item.alto || 'N/A'}</td>
            <td>${item.ancho || 'N/A'}</td>
            <td>${item.m2 ? `${item.m2} m²` : 'N/A'}</td>
            <td>${item.total ? `$${parseFloat(item.total).toLocaleString()}` : 'N/A'}</td>
          </tr>
        `;
      });
      // Calcula el número de la siguiente fila
      let nextRowNumber = cotizacion.detalles.length + 1;
        // Agregar filas adicionales de Dificultad, Retiro Láminas y Distancia
        if (cotizacion.monto_dificultad) {
          detalleItems += `
            <tr>
              <td>${nextRowNumber++}</td>
              <td colspan="4"><strong>Dificultad</strong></td>
              <td>$${parseFloat(cotizacion.monto_dificultad).toLocaleString()}</td>
            </tr>
          `;
        }
        if (cotizacion.monto_retiro_laminas) {
          detalleItems += `
            <tr>
              <td>${nextRowNumber++}</td>
              <td colspan="4"><strong>Retiro Láminas</strong></td>
              <td>$${parseFloat(cotizacion.monto_retiro_laminas).toLocaleString()}</td>
            </tr>
          `;
        }
    
        if (cotizacion.monto_distancia) {
          detalleItems += `
            <tr>
              <td>${nextRowNumber++}</td>
              <td colspan="4"><strong>Distancia</strong></td>
              <td>$${parseFloat(cotizacion.monto_distancia).toLocaleString()}</td>
            </tr>
          `;
        }
        if (cotizacion.descuento > 0) {
          detalleItems += `
            <tr>
              <td>${nextRowNumber++}</td>
              <td colspan="4"><strong>Descuento</strong></td>
              <td>-${parseFloat(cotizacion.descuento).toLocaleString()}%</td>
            </tr>
          `;
        }
    
      templateHTML = templateHTML.replace('{{detalleItems}}', detalleItems);

      const element = document.createElement('div');
      element.innerHTML = templateHTML;

      const opt = {
        filename: `cotizacion_${cotizacionId}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: 'mm', format: [320, 210], orientation: 'portrait' },
      };

      const pdfBase64 = await html2pdf().from(element).set(opt).outputPdf('datauristring');
      return pdfBase64.split(',')[1]; 
    } catch (error) {
      console.error('Error al generar el PDF:', error);
      alert('Ocurrió un error al generar el PDF. Por favor, inténtalo de nuevo.');
    }
  };

  const handleDownloadPDF = async () => {
    const pdfBase64 = await generatePDF();
    if (pdfBase64) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${pdfBase64}`;
      link.download = `cotizacion_${cotizacionId}.pdf`;
      link.click();
    }
  };

  const handleSendPDFByEmail = async () => {
    try {
      const pdfBase64 = await generatePDF();
      if (pdfBase64) {
        const token = localStorage.getItem('access_token');
        const destinatario = cliente.email;
        const asunto = `FilmSolar - Cotización #${cotizacionId}`;
        const mensaje = `
        <p>Estimado/a <strong>${cliente.nombre} ${cliente.apellido}</strong>,</p>
        <p>Adjunto encontrará el PDF de la <strong>cotización #${cotizacionId}</strong>.</p>
        <p>Detalles de la cotización:</p>
        <ul>
          <li><strong>Fecha de cotización: </strong>${fechaCotizacion}</li>
          <li><strong>Total:</strong> ${cotizacion.total_cotizacion.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</li>
          <li><strong>Tipo de instalación:</strong> ${cotizacion.tipo_instalacion}</li>
        </ul>
        <p>Si tiene alguna consulta, no dude en ponerse en contacto con nosotros.</p>
        <p>Saludos cordiales,</p>
        <p><strong>El equipo de FilmSolar</strong></p>
        <p>Correo: <a href="mailto:${process.env.REACT_APP_MAIL_CUENTA_PAGO}">${process.env.REACT_APP_MAIL_CUENTA_PAGO}</a></p>
      `;
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/send-email`,
          {
            destinatario,
            asunto,
            mensaje,
            pdf: pdfBase64,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        alert('El PDF se ha enviado correctamente por correo.');
      }
    } catch (error) {
      console.error('Error al enviar el PDF por correo:', error);
      alert('Ocurrió un error al enviar el PDF por correo. Por favor, inténtalo de nuevo.');
    }
  };

  const handleChangeEstado = async (nuevoEstado) => {
    try {
        const token = localStorage.getItem('access_token');
        
        // Primero, cambia el estado de la cotización
        await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/${cotizacionId}/cambiar-estado/`, 
        { estado: nuevoEstado }, 
        {
            headers: { Authorization: `Bearer ${token}` }
        });

        // Actualiza el estado localmente para reflejar el cambio
        setCotizacion((prevCotizacion) => ({
            ...prevCotizacion,
            estado: nuevoEstado
        }));

        // Si el estado es 'aprobado', crea la orden de venta
        if (nuevoEstado === 'aprobado') {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ordenes/crear-orden-desde-cotizacion/`, 
            { cotizacion_id: cotizacionId }, 
            {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Opcional: Mostrar un mensaje de éxito o actualizar algo en la UI si es necesario
            alert('Cotización aprobada y orden de venta creada exitosamente.');
        }

    } catch (error) {
        console.error(`Error al cambiar el estado de la cotización a ${nuevoEstado}:`, error);
        alert('Ocurrió un error al intentar aprobar la cotización y crear la orden de venta.');
    }
};


  const handleAprobarClick = () => {
    const confirmar = window.confirm('¿Estás seguro de que deseas aprobar esta cotización?');
    if (confirmar) {
      handleChangeEstado('aprobado');
    }
  };


  return (
    <>
      <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0 h-full flex">
        <div className="responsive-div p-6" style={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', width: '50%', maxWidth: 'none', height: '100%', borderLeft: '1px solid #ddd', boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>Detalle Cotización</p>
            <NavButton
              title="Cerrar"
              customFunc={onClose}
              color={currentColor}
              icon={<RiCloseCircleLine />}
              size="3xl"  // Aumenta el tamaño del ícono
              padding="p-4"  // Aumenta el padding del botón
            />
          </div>

          <div className="cotizacion-header">
            <div className="yellow-bar-left"></div>
            <h1 className="cotizacion-title">COTIZACIÓN</h1>
            <div className="yellow-bar-right"></div>
          </div>

          {/* Información del cliente */}
          {cliente && (
            <div className="cliente-info">
              <div className="left-info">
                <p><strong>Cliente:</strong> </p>
                <p>{cliente.nombre} {cliente.apellido}</p>
                <p>{cliente.whatsapp}</p>
                <p>{cliente.email}</p>
                <p>{cotizacion.direccion_instalacion}</p>
                <p>{cliente.comuna}</p>
                <p><strong>N° Cotización: </strong>{cotizacion.id_cotizacion}</p>
              </div>
              <div className="right-info">
                <p><strong>Estado:</strong> {cotizacion.estado}</p>
                <p><strong>Fecha Cotización:</strong> {moment(cotizacion.fecha_cotizacion).format('DD/MM/YYYY')}</p>
                <p><strong>Tipo Servicio:</strong> {cotizacion.tipo_instalacion}</p>
                <p><strong>Retiro Lamina:</strong> {cotizacion.retiro_laminas ? "Sí" : "No"}</p>
                <p><strong>Dificultad:</strong> {cotizacion.dificultad}</p>
                <p><strong>Tipo Propiedad:</strong> {cotizacion.tipo_propiedad}</p>
              </div>
            </div>
          )}

          <table className="items-table">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Item</th>
                <th>Alto</th>
                <th>Ancho</th>
                <th>M²</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cotizacion.detalles && cotizacion.detalles.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.item}</td>
                  <td>{item.alto || 'N/A'}</td>
                  <td>{item.ancho || 'N/A'}</td>
                  <td>{item.m2 ? `${item.m2} m²` : 'N/A'}</td>
                  <td>{item.total ? `$${parseFloat(item.total).toLocaleString()}` : 'N/A'}</td>
                </tr>
              ))}

              {/* Fila adicional según el valor */}
              {(() => {
                let extraRowIndex = cotizacion.detalles.length;

                return (
                  <>
                    {cotizacion.retiro_laminas && (
                      <tr>
                        <td>{++extraRowIndex}</td>
                        <td colSpan="4">Retiro de láminas</td>
                        <td>{`$${parseFloat(cotizacion.monto_retiro_laminas).toLocaleString()}`}</td>
                      </tr>
                    )}
                    {cotizacion.cobro_distancia && (
                      <tr>
                        <td>{++extraRowIndex}</td>
                        <td colSpan="4">Monto por distancia</td>
                        <td>{`$${parseFloat(cotizacion.monto_distancia).toLocaleString()}`}</td>
                      </tr>
                    )}
                    {cotizacion.dificultad !== 'normal' && (
                      <tr>
                        <td>{++extraRowIndex}</td>
                        <td colSpan="4">{`Dificultad: ${cotizacion.dificultad}`}</td>
                        <td>{`$${parseFloat(cotizacion.monto_dificultad).toLocaleString()}`}</td>
                      </tr>
                    )}
                    {cotizacion.descuento > 0 && (
                      <tr>
                        <td>{++extraRowIndex}</td>
                        <td colSpan="4">Descuento</td>
                        <td>{`$${parseFloat(cotizacion.descuento).toLocaleString()}`}</td>
                      </tr>
                    )}
                  </>
                );
              })()}
            </tbody>
          </table>

          {/* Monto total debajo de la tabla */}
          <div className="flex justify-end mt-4">
            <p className="font-semibold text-lg">Total: {(cotizacion.total_cotizacion / 1).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</p>
          </div>

          {/* Monto total de m2 debajo de la tabla */}
          <div className="flex justify-end mt-2">
            <p className="font-semibold text-lg">Total m²: {totalM2.toFixed(2)} m²</p>
          </div>

          <div className="flex justify-between mt-4">
            <div>
            <button onClick={handleDownloadPDF} type="button" className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-200 mr-2">
              Descargar PDF
            </button>
            <button onClick={handleSendPDFByEmail} type="button" className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200">
              Enviar por Email

            </button>
            </div>
            <div className="flex">
              {cotizacion.estado === 'pendiente' && (
                <>
                  <button
                    type="button"
                    className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition duration-200 mr-4"
                    onClick={handleAprobarClick}
                  >
                    Aprobar
                  </button>
                  <button
                    type="button"
                    className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-200"
                    onClick={() => handleChangeEstado('rechazado')}
                  >
                    Rechazar
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleCotizacion;
