import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { Header, DetalleInstalacion } from '../components';
import { Tabs, Tab } from '@mui/material';

const Instalacion = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [allData, setAllData] = useState([]);  // Guarda todos los datos
  const [filteredData, setFilteredData] = useState([]);
  const [detalleInstalacionVisible, setDetalleInstalacionVisible] = useState(false);
  const [selectedInstalacion, setSelectedInstalacion] = useState(null);
  const [selectedOrden, setSelectedOrden] = useState(null);
  const [selectedCotizacion, setSelectedCotizacion] = useState(null);

  // Función para obtener todas las instalaciones
  const fetchInstalaciones = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No se ha encontrado el token de autenticación.');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Llamada a la API para obtener todas las instalaciones
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/instalaciones/`, config);
      const instalaciones = response.data;

      // Obtener las ordenes y cotizaciones asociadas a cada instalación
      const instalacionesConDetalles = await Promise.all(
        instalaciones.map(async (instalacion) => {
          const ordenResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/ordenes/${instalacion.orden}`, config);
          const orden = ordenResponse.data;

          const cotizacionResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/${orden.cotizacion}`, config);
          const cotizacion = cotizacionResponse.data;

          // Unir los datos de la instalación, orden y cotización
          return {
            id_instalacion: instalacion.id_instalacion,
            solicitante: cotizacion.solicitante_nombre_completo,  
            fecha_instalacion: orden.fecha_instalacion,
            tipo_propiedad: cotizacion.tipo_propiedad,
            estado_instalacion: instalacion.estado_instalacion,
            direccion_instalacion: cotizacion.direccion_instalacion,
            instalacion,  // Guardar la instalación completa para el detalle
            orden,  // Guardar la orden completa
            cotizacion  // Guardar la cotización completa
          };
        })
      );

      // Guardar todos los datos en el estado
      setAllData(instalacionesConDetalles);

      // Aplicar filtro basado en la pestaña seleccionada
      filterData(instalacionesConDetalles, selectedTab);
    } catch (error) {
      console.error('Error al obtener las instalaciones:', error);
    }
  };

  useEffect(() => {
    fetchInstalaciones();  // Llamar a la función para obtener datos al montar el componente
  }, []);

  // Función para filtrar los datos basados en el tabIndex
  const filterData = (data, tabIndex) => {
    let newData = data || allData;  // Usa los datos proporcionados o los ya almacenados
    switch (tabIndex) {
      case 0:
        newData = newData.filter(item => item.estado_instalacion === 'pendiente');
        break;
      case 1:
        newData = newData.filter(item => item.estado_instalacion === 'realizada');
        break;
      case 2:
        newData = newData.filter(item => item.estado_instalacion === 'visita_terreno');
        break;
      default:
        newData = allData;
    }
    setFilteredData(newData);
  };
  const formattedData = filteredData.map((item) => ({
    ...item,
    fecha_instalacion: item.fecha_instalacion 
      ? new Date(item.fecha_instalacion).toLocaleDateString('es-CL', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).replace(/-/g, '/')  // Asegura el formato dd/MM/yyyy
      : '', // Si 'fecha_instalacion' es null o undefined, dejamos vacío
    hora_instalacion: item.fecha_instalacion
      ? new Date(item.fecha_instalacion).toLocaleTimeString('es-CL', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false  // Formato de 24 horas
        })
      : '', // Si 'fecha_instalacion' es null o undefined, dejamos vacío
  }));
  

  // Llamar a filterData cuando se cambia la pestaña
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    filterData(allData, newValue);  // Filtrar datos al cambiar de pestaña
  };

  const handleRowDoubleClick = (args) => {
    const rowData = args.rowData;

    const { instalacion, orden, cotizacion } = rowData || {};
    if (!instalacion || !orden || !cotizacion) {
      console.error('Faltan datos de instalación, orden o cotización');
      return;
    }

    setSelectedInstalacion(instalacion);
    setSelectedOrden(orden);
    setSelectedCotizacion(cotizacion);
    setDetalleInstalacionVisible(true);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Mis instalaciones" />

      <div className="flex justify-between items-center">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{
            '.MuiTab-root': {
              '&:hover': {
                color: '#FFa800',
              },
              '&.Mui-selected': {
                color: '#FFa800',
              },
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#FFa800',
            },
          }}
        >
          <Tab label="Pendiente" />
          <Tab label="Realizadas" />
          <Tab label="Visita Terreno" />
        </Tabs>
      </div>

      {detalleInstalacionVisible && (
        <DetalleInstalacion
          onClose={() => setDetalleInstalacionVisible(false)}
          instalacion={selectedInstalacion}
          orden={selectedOrden}
          cotizacion={selectedCotizacion}
        />
      )}
      {
        console.error(formattedData)
      }
      <GridComponent
        dataSource={formattedData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{ persistSelection: true, type: 'Single' }}  // Selección de filas individuales
        toolbar={[]}
        editSettings={{ allowEditing: false, allowDeleting: false }}  // Desactivar la edición
        allowSorting
        sortSettings={{ columns: [{ field: 'id_instalacion', direction: 'Descending' }] }}  // Orden descendente por ID Instalación
        className="mt-4"
        recordDoubleClick={handleRowDoubleClick}
      >
        <ColumnsDirective>
          <ColumnDirective field="id_instalacion" headerText="ID Instalación" width="120" textAlign="Center" />
          <ColumnDirective field="solicitante" headerText="Solicitante" width="200" textAlign="Center" />
          <ColumnDirective 
            field="fecha_instalacion" 
            headerText="Fecha Instalación" 
            width="150" 
            textAlign="Center"
            allowSorting={true} 
          />
          <ColumnDirective 
            field="hora_instalacion" 
            headerText="Hora Instalación" 
            width="150" 
            textAlign="Center"
            allowSorting={true} 
          />
          <ColumnDirective field="tipo_propiedad" headerText="Propiedad" width="150" textAlign="Center" />
          <ColumnDirective field="estado_instalacion" headerText="Estado" width="150" textAlign="Center" />
          <ColumnDirective field="direccion_instalacion" headerText="Dirección Instalación" width="200" textAlign="Center" />
        </ColumnsDirective>

        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Instalacion;
