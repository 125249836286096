import axios from 'axios';

// Crear una instancia de Axios
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});


// Configurar el interceptor
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Verificar si el error es por un token de acceso expirado
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.error("Hola")
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/token/refresh/`, { refresh: refreshToken });
        
        // Almacenar el nuevo token de acceso
        localStorage.setItem('access_token', data.access);
        
        // Actualizar el encabezado de la solicitud original con el nuevo token de acceso
        originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
        
        // Repetir la solicitud original con el nuevo token de acceso
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Error al refrescar el token:', refreshError);
        // Manejar el error, por ejemplo, redirigir al usuario al login
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// Exportar la instancia de Axios para que pueda ser usada en otros módulos
export default api;
